import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AddressService } from '../address/address.service';
import { OfferService } from '../offer/offer.service';
const addresses = ['Moldaugade', 'Moldrupsvej', 'Moldevej 9, st. th, 7100 Vejle', ' Fashion road'];
import { HoldingHomeService } from './holding-home.service';

@Component({
  selector: 'app-holding-home',
  templateUrl: './holding-home.component.html',
  styleUrls: ['./holding-home.component.scss']
})
export class HoldingHomeComponent implements OnInit {
  address: string;
  addressList: any = [];
  addressId: any;
  model: any = {isSummerHus: true};
  isValidType = true;
  tagTypeList: any = [
    { key: 1, value: 'Hårdt tag' },
    { key: 2, value: 'Fladt tag' },
    { key: 3, value: 'Stråtag' },
    { key: 4, value: 'Andet' }
  ];

  years: any = [];
  livingSpace: any = [
    { key: 110, value: 'Stuehus til landbrugsejendom' },
    { key: 120, value: 'Fritliggende enfamiliehus' },
    { key: 130, value: 'Rækkehus eller dobbelthuse' },
    { key: 140, value: 'Etageboligbebyggelse' },
    { key: 510, value: 'Sommerhus' }
  ];
  @ViewChild('addressForm', { read: NgForm, static: false })
  addressForm: NgForm;
  @Input() resetHoldingHomeFormSubject: Subject<any> = new Subject<any>();
  @Output() next = new EventEmitter<any>();
  @Input() defautKeyValue: any;
  constructor(private router: Router, private addressService: AddressService, private offerService: OfferService) { }
  disp(): string[] {
    var currentYear = new Date().getFullYear(), years = [];
    let startYear = 1920;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map(term => term.length < 0 ? []
        : this.addressList.filter(v => v.tekst.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 50))
    )
  resultFormatBandListValue(value: any) {
    return value.tekst;
  }
  ngOnInit() {
    this.years = this.disp();
    this.resetHoldingHomeFormSubject.subscribe(response => {
      if (response) {
        var element = localStorage.getItem(response.name);
        if (element) {
          var data = JSON.parse(element);
          this.model = data;
        }
        else if (this.model.eleName != response.name && response.isCompleted) {
          this.model.eleName = response.name;
          this.model.isCompleted = response.isCompleted;
          localStorage.setItem(response.name, JSON.stringify(this.model));
        }
        else {
          this.addressForm.reset();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.addressForm.statusChanges.subscribe((result) => {
      if (result == "VALID") {
        this.offerService.setAddressValidationObs(true);
      }
      //     console.log(result)
    });
  }
  ngOnChanges(changes): void {
    if(changes.defautKeyValue)
    {
      this.SetResetVehicleFormSubject(this.defautKeyValue);
      console.log(this.defautKeyValue);
    }
  }
  SetResetVehicleFormSubject(response){
    if (response) {
      const element = localStorage.getItem(response.name);
      if (element) {
        const data = JSON.parse(element);
        setTimeout(() => {
          console.log('address data : ', data);

          this.model = data;
        }, 1000);
      }
    }
  }
  GotoVehicle() {
    this.router.navigateByUrl('/vehicle');
  }

  GotoAddress() {
    this.router.navigateByUrl('/address');
  }

  checkInputLength() {
    if (this.address.length) {
      this.getAddressDetails();
    } else {
      return;
    }
  }

  getAddressDetails() {
    this.addressService.getAddressDetail(this.address)
      .subscribe((res: any) => {
        this.addressList = [];
        if (res.data.data) {
          this.addressList = res.data.data;
        }
      }, error => {

      });
  }

  getSelectedItem(event) {
    this.model = event.item.adresse;
    this.model.search = event.item;
    this.addressId = '';
    if (this.model) {
      this.addressId = this.model.id;
      this.isValidType = true;
      this.getAddressDetailsById(this.addressId);
    }
  }

  getAddressDetailsById(id) {
    this.addressService.getAddressDetailsById(id)
      .subscribe((res: any) => {
        if (res && res.data.data) {
          const responseDetail = res.data.data[0];
          if(responseDetail.ENH_ANVEND_KODE == 510){
          if (responseDetail.bygning.TAG_KODE === 1) {
            this.model.tagType = 2;
          } else if (responseDetail.bygning.TAG_KODE === 7) {
            this.model.tagType = 3;
          } else if (responseDetail.bygning.TAG_KODE === 11 || responseDetail.bygning.TAG_KODE === 12
              || responseDetail.bygning.TAG_KODE === 90) {
            this.model.tagType = 4;
          } else if (responseDetail.bygning.TAG_KODE === 2 || responseDetail.bygning.TAG_KODE === 3
            || responseDetail.bygning.TAG_KODE === 3 || responseDetail.bygning.TAG_KODE === 4
            || responseDetail.bygning.TAG_KODE === 5 || responseDetail.bygning.TAG_KODE === 6) {
          this.model.tagType = 1;
        }
          // this.model.tagType = responseDetail.bygning.TAG_KODE;
          this.model.year = responseDetail.bygning.OPFOERELSE_AAR;
          this.model.housingType = responseDetail.ENH_ANVEND_KODE;
          this.model.place = responseDetail.bygning.BYG_BOLIG_ARL_SAML;
      }
      else{
        this.isValidType = false;
      }
        }
      }, error => {

      });
  }

  formSubmit(): void {
    if (this.addressForm.valid) {
      this.model.isSummerHus= true;
      this.next.emit(this.model);
    }
  }

}

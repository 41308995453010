import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class DefaultLayoutComponent implements OnInit {

  isChildShow: boolean = false;
  isUpperMenu: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}

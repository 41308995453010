export const dataConstant = {
  MessageType: {
    danger: 'danger',
    warning: 'warning',
    success: 'success-msg',
    failure: 'failure',
    error: 'Error-msg',
  },
  APIStatus: {
    Success: 'Success',
    Failure: 'Failure',
    Warning: 'Warning',
    RecordNotFound: 'Record Detail Not Exist',
    Exists: 'Exists',
    NotFound: 'NotFound',
    DuplicateRecord: 'DuplicateRecord',
    ChildRecordExist: 'ChildRecordExist'
  },
  StatusCode: {
    //Success
    200: '200',//OK
    201: '201',//Created
    204: '204',//No Content
    //Redirection
    304: '304',//Not Modified
    //Client Error
    400: '400',//Bad Request
    401: '401',//Unauthorized
    402: '402',//Created
    403: '403',//Forbidden
    404: '404',//Not Found
    409: '409',//Conflict
  },
  datePattern: /^\d{2}-\d{2}-\d{4}$/,
  NumberWithDecimal: /^[0-9]+(\.[0-9]{1,2})?$/,
  DecimalWithSevenPointPattern: /^[1-9][0-9]{0,4}(?:,?[0-9]{3}){0,2}(?:\.[0-9]{0,7})?$/,
  Numberony: /^[0-9]*$/,
  PasswordPattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?!.*\s).{6,}$/,
  PhoneNoPattern: /^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{4}$/,
  EmailPattren: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  WeekDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  Months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  DateFormatArray:
    ['dd/MM/yyyy'                       //00. 13-12-2018
      , 'dd-MMMM-yyyy'                //01. 13-12-2018
      , 'yyyy/MM/dd'                  //02. 2018/12/13
      , 'dd.MM.yyyy'                  //03. 13.12.2016
      , 'shortDate'                   //04.
      , 'MM/dd/yyyy'                  //05. 12/13/2018
      , 'MM/dd/yyyy HH:mm'            //06. 12/13/2018 15:12
      , 'yyyy-MM-dd hh:mm a'          //07. 2018-12-13 03:12 PM
      , 'yyyy/MM/dd hh:mm a'          //08. 2018/12/13 03:12 PM
      , 'yyyy-MM-dd'                  //09. 2018-12-13
      , 'hh:mm a'                     //10. 03:12 PM
      , 'MMM dd,yyyy hh:mm a'         //11. DEC 13,2018 03:12 PM
      , 'dd MMMM, yyyy'               //12. 13 DECEMBER, 2018
      , 'dd MMM yyyy'                 //13. 13 DEC, 2018
      , 'MM/DD/YYYY  HH:mm:ss'        //14. 12/13/2018 15:12:20
    ],

  Pagination: {
    IsCSVExport: 'false',
    ItemsPerPage: 10,
    Page: 1,
    SearchColumns: [],
    SortColumns: []
  },
  ModuleNames: {
    User: {
      Name: 'User'
    }
  },
  PageSize: [10, 25, 50, 75, 100],
  DefaultImage: 'no_image.png',
  SearchDataType:
  {
    StringContains: 'StringContains',
    StringEquals: 'StringEquals',
    DateTime: 'DateTime',
    Date: 'Date',
    Number: 'Number',
    Decimal: 'Decimal',
    LongNumber: 'LongNumber',
    Boolean: 'Boolean',
  },
  Status: [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ],
  EnableColumnFiltering: true,
  IsPinOption: true,
  IsShowHideColumn: true,
  IsShowClearFilter: true,
  DisplayDateFormat: "dd/MM/yyyy",
  MinTypeAheadChar: 4,
  Admin: "Admin",
  DefaultStatus: [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ],

  brandList: [
    { key: 1, value: 'Alfa Romeo' },
    { key: 2, value: 'Alpina' },
    { key: 3, value: 'Aston Martin' },
    { key: 4, value: 'Audi' },
    { key: 5, value: 'Bentley' },
    { key: 6, value: 'BMW' },
    { key: 7, value: 'Cadillac' },
    { key: 8, value: 'Chevrolet' },
    { key: 9, value: 'Chrysler' },
    { key: 10, value: 'Citroën' },
    { key: 11, value: 'Dacia' },
    { key: 12, value: 'Daewoo' },
    { key: 13, value: 'Daihatsu' },
    { key: 14, value: 'Dodge' },
    { key: 15, value: 'Ferrari' },
    { key: 16, value: 'Fiat' },
    { key: 17, value: 'Ford' },
    { key: 18, value: 'Honda' },
    { key: 19, value: 'Hummer' },
    { key: 20, value: 'Hyundai' },
    { key: 21, value: 'Infiniti' },
    { key: 22, value: 'Iveco' },
    { key: 23, value: 'Jaguar' },
    { key: 24, value: 'Jeep' },
    { key: 25, value: 'Kia' },
    { key: 26, value: 'Lada' },
    { key: 27, value: 'Lamborghini' },
    { key: 28, value: 'Lancia' },
    { key: 29, value: 'Land Rover' },
    { key: 30, value: 'LDV' },
    { key: 31, value: 'Lexus' },
    { key: 32, value: 'Lotus' },
    { key: 33, value: 'Maserati' },
    { key: 34, value: 'Mazda' },
    { key: 35, value: 'Mercedes' },
    { key: 36, value: 'Mercury' },
    { key: 37, value: 'MG' },
    { key: 38, value: 'Mini' },
    { key: 39, value: 'Mitsubishi' },
    { key: 40, value: 'Morgan' },
    { key: 41, value: 'Morris' },
    { key: 42, value: 'Nissan' },
    { key: 43, value: 'Opel' },
    { key: 44, value: 'Peugeot' },
    { key: 45, value: 'Pontiac' },
    { key: 46, value: 'Porsche' },
    { key: 47, value: 'Renault' },
    { key: 48, value: 'Rolls Royce' },
    { key: 49, value: 'Rover' },
    { key: 50, value: 'Saab' },
    { key: 51, value: 'Seat' },
    { key: 52, value: 'Skoda' },
    { key: 53, value: 'Smart' },
    { key: 54, value: 'Ssangyong' },
    { key: 55, value: 'Subaru' },
    { key: 56, value: 'Suzuki' },
    { key: 57, value: 'Tesla' },
    { key: 58, value: 'Toyota' },
    { key: 59, value: 'Volvo' },
    { key: 60, value: 'VW' },
    { key: 61, value: 'Wartburg' },
    { key: 62, value: 'VOLKSWAGEN' }
  ],

  modelList: [
    { key: 1, value: '146', brandKey: 1 },
    { key: 2, value: '146', brandKey: 1 },
    { key: 3, value: '147', brandKey: 1 },
    { key: 4, value: '147 GTA', brandKey: 1 },
    { key: 6, value: '166', brandKey: 1 },
    { key: 6, value: '166', brandKey: 1 },
    { key: 7, value: '166 GTA', brandKey: 1 },
    { key: 8, value: '169', brandKey: 1 },
    { key: 9, value: '164', brandKey: 1 },
    { key: 10, value: '166', brandKey: 1 },
    { key: 11, value: '33', brandKey: 1 },
    { key: 12, value: '76', brandKey: 1 },
    { key: 13, value: 'Second model', brandKey: 1 },
    { key: 14, value: 'Brera', brandKey: 1 },
    { key: 16, value: 'Giulietta', brandKey: 1 },
    { key: 16, value: 'GT', brandKey: 1 },
    { key: 17, value: 'GTV', brandKey: 1 },
    { key: 18, value: 'MiTo', brandKey: 1 },
    { key: 19, value: 'Spider', brandKey: 1 },

    { key: 20, value: 'Anden model', brandKey: 2 },
    { key: 21, value: 'B3', brandKey: 2 },
    { key: 22, value: 'B6', brandKey: 2 },

    { key: 23, value: 'Anden model', brandKey: 3 },
    { key: 24, value: 'DB9', brandKey: 3 },
    { key: 26, value: 'V8 vantage', brandKey: 3 },

    { key: 26, value: '100', brandKey: 4 },
    { key: 27, value: '80', brandKey: 4 },
    { key: 28, value: '90', brandKey: 4 },
    { key: 29, value: 'A1', brandKey: 4 },
    { key: 30, value: 'A2', brandKey: 4 },
    { key: 31, value: 'A3', brandKey: 4 },
    { key: 32, value: 'A3 Cabriolet', brandKey: 4 },
    { key: 33, value: 'A3 Sportback', brandKey: 4 },
    { key: 34, value: 'A4', brandKey: 4 },
    { key: 36, value: 'A4 Allroad', brandKey: 4 },
    { key: 36, value: 'A4 Cabriolet', brandKey: 4 },
    { key: 37, value: 'A6', brandKey: 4 },
    { key: 38, value: 'A6 Cabriolet', brandKey: 4 },
    { key: 39, value: 'A6 Sportback', brandKey: 4 },
    { key: 40, value: 'A6', brandKey: 4 },
    { key: 41, value: 'A6 Allroad', brandKey: 4 },
    { key: 42, value: 'A7 Sportback', brandKey: 4 },
    { key: 43, value: 'A8', brandKey: 4 },
    { key: 44, value: 'Anden model', brandKey: 4 },
    { key: 46, value: 'Q3', brandKey: 4 },
    { key: 46, value: 'Q6', brandKey: 4 },
    { key: 47, value: 'Q7', brandKey: 4 },
    { key: 48, value: 'R8', brandKey: 4 },
    { key: 49, value: 'RS2', brandKey: 4 },
    { key: 60, value: 'RS4', brandKey: 4 },
    { key: 61, value: 'RS6', brandKey: 4 },
    { key: 62, value: 'S2', brandKey: 4 },
    { key: 63, value: 'S3', brandKey: 4 },
    { key: 64, value: 'S4', brandKey: 4 },
    { key: 66, value: 'S6', brandKey: 4 },
    { key: 66, value: 'S6', brandKey: 4 },
    { key: 67, value: 'S8', brandKey: 4 },
    { key: 68, value: 'TT', brandKey: 4 },
    { key: 69, value: 'TT Roadster', brandKey: 4 },

    { key: 60, value: 'Anden model', brandKey: 5 },
    { key: 61, value: 'Arnage', brandKey: 5 },
    { key: 62, value: 'Continental', brandKey: 5 },
    { key: 63, value: 'Eight', brandKey: 5 },

    { key: 64, value: '116d', brandKey: 6 },
    { key: 65, value: '116i', brandKey: 6 },
    { key: 66, value: '118 Cabriolet', brandKey: 6 },
    { key: 67, value: '118d', brandKey: 6 },
    { key: 68, value: '118i', brandKey: 6 },
    { key: 69, value: '120 Cabriolet', brandKey: 6 },
    { key: 70, value: '120 Coupé', brandKey: 6 },
    { key: 71, value: '120d', brandKey: 6 },
    { key: 72, value: '120i', brandKey: 6 },
    { key: 73, value: '123 Coupé', brandKey: 6 },
    { key: 74, value: '123d', brandKey: 6 },
    { key: 75, value: '126 Cabriolet', brandKey: 6 },
    { key: 76, value: '126 Coupé', brandKey: 6 },
    { key: 77, value: '130i', brandKey: 6 },
    { key: 78, value: '136 Coupé', brandKey: 6 },
    { key: 79, value: '220i', brandKey: 6 },
    { key: 80, value: '316Ci Coupé', brandKey: 6 },
    { key: 81, value: '316d', brandKey: 6 },
    { key: 82, value: '316i', brandKey: 6 },
    { key: 83, value: '316Ti', brandKey: 6 },
    { key: 84, value: '318Ci Cabriolet', brandKey: 6 },
    { key: 85, value: '318Ci Coupé', brandKey: 6 },
    { key: 86, value: '318d', brandKey: 6 },
    { key: 87, value: '318i', brandKey: 6 },
    { key: 88, value: '318iS', brandKey: 6 },
    { key: 89, value: '318TDS', brandKey: 6 },
    { key: 90, value: '318Ti', brandKey: 6 },
    { key: 91, value: '320Ci Cabriolet', brandKey: 6 },
    { key: 92, value: '320Ci Coupé', brandKey: 6 },
    { key: 93, value: '320d', brandKey: 6 },
    { key: 94, value: '320i', brandKey: 6 },
    { key: 95, value: '323Ci Cabriolet', brandKey: 6 },
    { key: 96, value: '323Ci Coupé', brandKey: 6 },
    { key: 97, value: '323i', brandKey: 6 },
    { key: 98, value: '323Ti', brandKey: 6 },
    { key: 99, value: '324TD', brandKey: 6 },
    { key: 100, value: '326Ci Cabriolet', brandKey: 6 },
    { key: 101, value: '326Ci Coupé', brandKey: 6 },
    { key: 102, value: '326d', brandKey: 6 },
    { key: 103, value: '326e', brandKey: 6 },
    { key: 104, value: '326i', brandKey: 6 },
    { key: 105, value: '326TD', brandKey: 6 },
    { key: 106, value: '326TDS', brandKey: 6 },
    { key: 107, value: '326Xi', brandKey: 6 },
    { key: 108, value: '328Ci Cabriolet', brandKey: 6 },
    { key: 109, value: '328Ci Coupé', brandKey: 6 },
    { key: 110, value: '328i', brandKey: 6 },
    { key: 111, value: '330Ci Cabriolet', brandKey: 6 },
    { key: 112, value: '330Ci Coupé', brandKey: 6 },
    { key: 113, value: '330d', brandKey: 6 },
    { key: 114, value: '330i', brandKey: 6 },
    { key: 115, value: '330Xd', brandKey: 6 },
    { key: 116, value: '336Ci Cabriolet', brandKey: 6 },
    { key: 117, value: '336Ci Coupé', brandKey: 6 },
    { key: 118, value: '336d', brandKey: 6 },
    { key: 119, value: '336i', brandKey: 6 },
    { key: 120, value: '618i', brandKey: 6 },
    { key: 121, value: '620d', brandKey: 6 },
    { key: 122, value: '620i', brandKey: 6 },
    { key: 123, value: '623i', brandKey: 6 },
    { key: 124, value: '626d', brandKey: 6 },
    { key: 125, value: '626i', brandKey: 6 },
    { key: 126, value: '626td', brandKey: 6 },
    { key: 127, value: '626TDS', brandKey: 6 },
    { key: 128, value: '626xd', brandKey: 6 },
    { key: 129, value: '628i', brandKey: 6 },
    { key: 130, value: '630d', brandKey: 6 },
    { key: 131, value: '630d Gran Turismo', brandKey: 6 },
    { key: 132, value: '630i', brandKey: 6 },
    { key: 133, value: '630Xd', brandKey: 6 },
    { key: 134, value: '630d', brandKey: 6 },
    { key: 135, value: '636d Gran Turismo', brandKey: 6 },
    { key: 136, value: '636i', brandKey: 6 },
    { key: 137, value: '636i Gran Turismo', brandKey: 6 },
    { key: 138, value: '640i', brandKey: 6 },
    { key: 139, value: '646i', brandKey: 6 },
    { key: 140, value: '660i', brandKey: 6 },
    { key: 141, value: '660i Gran Turismo', brandKey: 6 },
    { key: 142, value: '630i', brandKey: 6 },
    { key: 143, value: '636d', brandKey: 6 },
    { key: 144, value: '640d', brandKey: 6 },
    { key: 145, value: '640i', brandKey: 6 },
    { key: 146, value: '646Ci', brandKey: 6 },
    { key: 147, value: '660i', brandKey: 6 },
    { key: 148, value: '728i', brandKey: 6 },
    { key: 149, value: '730d', brandKey: 6 },
    { key: 150, value: '730i', brandKey: 6 },
    { key: 151, value: '736i', brandKey: 6 },
    { key: 152, value: '740d', brandKey: 6 },
    { key: 153, value: '740i', brandKey: 6 },
    { key: 154, value: '746iA', brandKey: 6 },
    { key: 155, value: '746iL', brandKey: 6 },
    { key: 156, value: '760i', brandKey: 6 },
    { key: 157, value: '760iL', brandKey: 6 },
    { key: 158, value: '760Li', brandKey: 6 },
    { key: 159, value: '760Li', brandKey: 6 },
    { key: 160, value: '860CSi', brandKey: 6 },
    { key: 161, value: '860i', brandKey: 6 },
    { key: 162, value: 'Anden model', brandKey: 6 },
    { key: 163, value: 'M3', brandKey: 6 },
    { key: 164, value: 'M3 Cabriolet', brandKey: 6 },
    { key: 165, value: 'M6', brandKey: 6 },
    { key: 166, value: 'M6', brandKey: 6 },
    { key: 167, value: 'X1', brandKey: 6 },
    { key: 168, value: 'X3', brandKey: 6 },
    { key: 169, value: 'X6', brandKey: 6 },
    { key: 170, value: 'X6', brandKey: 6 },
    { key: 171, value: 'Z4', brandKey: 6 },
    { key: 172, value: 'Z4 Coupé', brandKey: 6 },
    { key: 173, value: 'Z8', brandKey: 6 },
    { key: 174, value: '3-Serie', brandKey: 6 },

    { key: 173, value: 'Anden model', brandKey: 7 },
    { key: 174, value: 'BLS', brandKey: 7 },
    { key: 175, value: 'CTS', brandKey: 7 },
    { key: 176, value: 'Eldorado', brandKey: 7 },
    { key: 177, value: 'Escalade', brandKey: 7 },
    { key: 178, value: 'SRX', brandKey: 7 },
    { key: 179, value: 'STS', brandKey: 7 },
    { key: 180, value: 'XLR', brandKey: 7 },

    { key: 181, value: 'Alero', brandKey: 8 },
    { key: 182, value: 'Anden model', brandKey: 8 },
    { key: 183, value: 'Astro', brandKey: 8 },
    { key: 184, value: 'Aveo', brandKey: 8 },
    { key: 185, value: 'Beretta', brandKey: 8 },
    { key: 186, value: 'Blazer', brandKey: 8 },
    { key: 187, value: 'Camaro', brandKey: 8 },
    { key: 188, value: 'Captiva', brandKey: 8 },
    { key: 189, value: 'Corvette', brandKey: 8 },
    { key: 190, value: 'Cruze', brandKey: 8 },
    { key: 191, value: 'Epica', brandKey: 8 },
    { key: 192, value: 'Evanda', brandKey: 8 },
    { key: 193, value: 'Express', brandKey: 8 },
    { key: 194, value: 'HHR', brandKey: 8 },
    { key: 195, value: 'Kalos', brandKey: 8 },
    { key: 196, value: 'Lacetti', brandKey: 8 },
    { key: 197, value: 'Lanos', brandKey: 8 },
    { key: 198, value: 'Leganza', brandKey: 8 },
    { key: 199, value: 'Matiz', brandKey: 8 },
    { key: 200, value: 'Nexia', brandKey: 8 },
    { key: 201, value: 'Nubira', brandKey: 8 },
    { key: 202, value: 'Orlando', brandKey: 8 },
    { key: 203, value: 'Pick-Up', brandKey: 8 },
    { key: 204, value: 'Silverado', brandKey: 8 },
    { key: 205, value: 'Spark', brandKey: 8 },
    { key: 206, value: 'Tacuma', brandKey: 8 },
    { key: 207, value: 'Tahoe', brandKey: 8 },
    { key: 208, value: 'Trans Sport', brandKey: 8 },
    { key: 209, value: 'TransCar', brandKey: 8 },
    { key: 210, value: 'Lacetti', brandKey: 8 },

    { key: 211, value: '300C', brandKey: 9 },
    { key: 212, value: '300M', brandKey: 9 },
    { key: 213, value: 'Anden model', brandKey: 9 },
    { key: 214, value: 'Crossfire', brandKey: 9 },
    { key: 215, value: 'Grand Voyager', brandKey: 9 },
    { key: 216, value: 'Le Baron', brandKey: 9 },
    { key: 217, value: 'Neon', brandKey: 9 },
    { key: 218, value: 'New Yorker', brandKey: 9 },
    { key: 219, value: 'PT Cruiser', brandKey: 9 },
    { key: 220, value: 'Sebring', brandKey: 9 },
    { key: 221, value: 'Sebring Cabriolet', brandKey: 9 },
    { key: 222, value: 'Stratus', brandKey: 9 },
    { key: 223, value: 'Vision', brandKey: 9 },
    { key: 224, value: 'Voyager', brandKey: 9 },

    { key: 225, value: 'Anden model', brandKey: 10 },
    { key: 226, value: 'AX', brandKey: 10 },
    { key: 227, value: 'Berlingo', brandKey: 10 },
    { key: 228, value: 'BX', brandKey: 10 },
    { key: 229, value: 'C-Crosser', brandKey: 10 },
    { key: 230, value: 'C1', brandKey: 10 },
    { key: 231, value: 'C2', brandKey: 10 },
    { key: 232, value: 'C3', brandKey: 10 },
    { key: 233, value: 'C3 Picasso', brandKey: 10 },
    { key: 234, value: 'C3 Pluriel', brandKey: 10 },
    { key: 235, value: 'C4', brandKey: 10 },
    { key: 236, value: 'C4 Aircross', brandKey: 10 },
    { key: 237, value: 'C4 Picasso', brandKey: 10 },
    { key: 238, value: 'Voyager', brandKey: 10 },
    { key: 239, value: 'C5', brandKey: 10 },
    { key: 240, value: 'C6', brandKey: 10 },
    { key: 241, value: 'C8', brandKey: 10 },
    { key: 242, value: 'CX', brandKey: 10 },
    { key: 243, value: 'DS3', brandKey: 10 },
    { key: 244, value: 'DS4', brandKey: 10 },
    { key: 245, value: 'DS5', brandKey: 10 },
    { key: 246, value: 'Evasion', brandKey: 10 },
    { key: 247, value: 'Grand C4 Picasso', brandKey: 10 },
    { key: 248, value: 'Jumper', brandKey: 10 },
    { key: 249, value: 'Jumpy', brandKey: 10 },
    { key: 250, value: 'Nemo', brandKey: 10 },
    { key: 251, value: 'Saxo', brandKey: 10 },
    { key: 252, value: 'Xantia', brandKey: 10 },
    { key: 253, value: 'XM', brandKey: 10 },
    { key: 254, value: 'Xsara', brandKey: 10 },
    { key: 255, value: 'Xsara Picasso', brandKey: 10 },
    { key: 256, value: 'ZX', brandKey: 10 },

    { key: 257, value: 'Anden model', brandKey: 11 },
    { key: 258, value: 'Sandero', brandKey: 11 },

    { key: 259, value: 'Anden model', brandKey: 12 },
    { key: 260, value: 'Damas', brandKey: 12 },
    { key: 261, value: 'Gentra', brandKey: 12 },
    { key: 262, value: 'Kalos', brandKey: 12 },
    { key: 263, value: 'Lacetti', brandKey: 12 },
    { key: 264, value: 'Lanos', brandKey: 12 },
    { key: 265, value: 'Leganza', brandKey: 12 },
    { key: 266, value: 'Matiz', brandKey: 12 },
    { key: 267, value: 'Nexia', brandKey: 12 },
    { key: 268, value: 'Nubira', brandKey: 12 },
    { key: 269, value: 'Rezzo', brandKey: 12 },
    { key: 270, value: 'Statesman', brandKey: 12 },
    { key: 271, value: 'Tico', brandKey: 12 },
    { key: 272, value: 'Tosca', brandKey: 12 },
    { key: 273, value: 'Win-Storm', brandKey: 12 },

    { key: 274, value: 'Anden model', brandKey: 13 },
    { key: 275, value: 'Applause', brandKey: 13 },
    { key: 276, value: 'Charade', brandKey: 13 },
    { key: 277, value: 'Cuore', brandKey: 13 },
    { key: 278, value: 'Sirion', brandKey: 13 },
    { key: 279, value: 'Terios', brandKey: 13 },
    { key: 280, value: 'YRV', brandKey: 13 },


    { key: 281, value: 'Anden model', brandKey: 14 },
    { key: 282, value: 'Avanger', brandKey: 14 },
    { key: 283, value: 'Caliber', brandKey: 14 },
    { key: 284, value: 'Durango', brandKey: 14 },
    { key: 285, value: 'Grand Caravan', brandKey: 14 },
    { key: 286, value: 'Journey', brandKey: 14 },
    { key: 287, value: 'Magnum', brandKey: 14 },
    { key: 288, value: 'Nitro', brandKey: 14 },
    { key: 289, value: 'Ram', brandKey: 14 },

    { key: 290, value: '328', brandKey: 15 },
    { key: 291, value: '348', brandKey: 15 },
    { key: 292, value: '360', brandKey: 15 },
    { key: 293, value: '456', brandKey: 15 },
    { key: 294, value: '599', brandKey: 15 },
    { key: 295, value: '612', brandKey: 15 },
    { key: 296, value: 'Anden model', brandKey: 15 },
    { key: 297, value: 'California', brandKey: 15 },
    { key: 298, value: 'F355', brandKey: 15 },
    { key: 299, value: 'F430', brandKey: 15 },
    { key: 300, value: 'Mondial', brandKey: 15 },

    { key: 301, value: '124', brandKey: 16 },
    { key: 302, value: '128', brandKey: 16 },
    { key: 303, value: '500', brandKey: 16 },
    { key: 304, value: '500C', brandKey: 16 },
    { key: 305, value: 'Anden model', brandKey: 16 },
    { key: 306, value: 'Barchetta', brandKey: 16 },
    { key: 307, value: 'Brava', brandKey: 16 },
    { key: 308, value: 'Bravo', brandKey: 16 },
    { key: 309, value: 'Cinquecento', brandKey: 16 },
    { key: 310, value: 'Coupé', brandKey: 16 },
    { key: 311, value: 'Croma', brandKey: 16 },
    { key: 312, value: 'Doblò', brandKey: 16 },
    { key: 313, value: 'Ducato', brandKey: 16 },
    { key: 314, value: 'Ducato 14', brandKey: 16 },
    { key: 315, value: 'Ducato 15', brandKey: 16 },
    { key: 316, value: 'Ducato 33', brandKey: 16 },
    { key: 317, value: 'Fiorini', brandKey: 16 },
    { key: 318, value: 'Grande Punto', brandKey: 16 },
    { key: 319, value: 'Idea', brandKey: 16 },
    { key: 320, value: 'Marea', brandKey: 16 },
    { key: 321, value: 'Multipla', brandKey: 16 },

    { key: 322, value: 'Anden model', brandKey: 17 },
    { key: 323, value: 'B-MAX', brandKey: 17 },
    { key: 324, value: 'C-MAX', brandKey: 17 },
    { key: 325, value: 'Cougar', brandKey: 17 },
    { key: 326, value: 'Escort', brandKey: 17 },
    { key: 327, value: 'Escort Cabriolet', brandKey: 17 },
    { key: 328, value: 'Explorer', brandKey: 17 },
    { key: 329, value: 'Fiesta', brandKey: 17 },
    { key: 330, value: 'Focus', brandKey: 17 },
    { key: 331, value: 'Focus Cabriolet', brandKey: 17 },
    { key: 332, value: 'Fusion', brandKey: 17 },
    { key: 333, value: 'Galaxy', brandKey: 17 },
    { key: 334, value: 'Grand C-Max', brandKey: 17 },
    { key: 335, value: 'Ka', brandKey: 17 },
    { key: 336, value: 'Kuga', brandKey: 17 },
    { key: 337, value: 'Maverick', brandKey: 17 },
    { key: 338, value: 'Mondeo', brandKey: 17 },
    { key: 339, value: 'Mustang', brandKey: 17 },
    { key: 340, value: 'Orion', brandKey: 17 },
    { key: 341, value: 'Probe', brandKey: 17 },
    { key: 342, value: 'Puma', brandKey: 17 },
    { key: 343, value: 'S-MAX', brandKey: 17 },
    { key: 344, value: 'Scorpio', brandKey: 17 },
    { key: 345, value: 'Sierra', brandKey: 17 },
    { key: 346, value: 'StreetKa', brandKey: 17 },
    { key: 347, value: 'Transit', brandKey: 17 },
    { key: 348, value: 'Transit 280S', brandKey: 17 },
    { key: 349, value: 'Transit 300M', brandKey: 17 },
    { key: 350, value: 'Transit 300S', brandKey: 17 },
    { key: 351, value: 'Transit Tourneo', brandKey: 17 },
    { key: 352, value: 'V8', brandKey: 17 },

    { key: 353, value: 'Accord', brandKey: 18 },
    { key: 354, value: 'Anden model', brandKey: 18 },
    { key: 355, value: 'Civic', brandKey: 18 },
    { key: 356, value: 'CR-V', brandKey: 18 },
    { key: 357, value: 'CR-X', brandKey: 18 },
    { key: 358, value: 'CR-Z', brandKey: 18 },
    { key: 359, value: 'FR-V', brandKey: 18 },
    { key: 360, value: 'HR-V', brandKey: 18 },
    { key: 361, value: 'Insight', brandKey: 18 },
    { key: 362, value: 'Integra', brandKey: 18 },
    { key: 363, value: 'Jazz', brandKey: 18 },
    { key: 364, value: 'Legend', brandKey: 18 },
    { key: 365, value: 'Logo', brandKey: 18 },
    { key: 365, value: 'Prelude', brandKey: 18 },
    { key: 366, value: 'S2000', brandKey: 18 },
    { key: 367, value: 'Shuttle', brandKey: 18 },
    { key: 368, value: 'Stream', brandKey: 18 },

    { key: 369, value: 'Anden model', brandKey: 19 },
    { key: 370, value: 'H1', brandKey: 19 },
    { key: 371, value: 'H2', brandKey: 19 },
    { key: 372, value: 'H3', brandKey: 19 },

    { key: 373, value: 'Accent', brandKey: 20 },
    { key: 374, value: 'Anden model', brandKey: 20 },
    { key: 375, value: 'Atos', brandKey: 20 },
    { key: 376, value: 'Coupé', brandKey: 20 },
    { key: 377, value: 'Elantra', brandKey: 20 },
    { key: 378, value: 'Galloper', brandKey: 20 },
    { key: 379, value: 'Getz', brandKey: 20 },
    { key: 380, value: 'H-1', brandKey: 20 },
    { key: 381, value: 'i10', brandKey: 20 },
    { key: 382, value: 'i20', brandKey: 20 },
    { key: 383, value: 'i30', brandKey: 20 },
    { key: 384, value: 'i40', brandKey: 20 },
    { key: 385, value: 'ix20', brandKey: 20 },
    { key: 386, value: 'ix35', brandKey: 20 },
    { key: 387, value: 'Matrix', brandKey: 20 },
    { key: 388, value: 'Pony', brandKey: 20 },
    { key: 389, value: 'Santa Fe', brandKey: 20 },
    { key: 390, value: 'Sonata', brandKey: 20 },
    { key: 391, value: 'Terracan', brandKey: 20 },
    { key: 392, value: 'Trajet', brandKey: 20 },
    { key: 393, value: 'Tucson', brandKey: 20 },
    { key: 394, value: 'Veloster', brandKey: 20 },

    { key: 395, value: 'Anden model', brandKey: 21 },
    { key: 396, value: 'FX45', brandKey: 21 },

    { key: 397, value: 'Anden model', brandKey: 22 },
    { key: 398, value: 'Daily', brandKey: 22 },

    { key: 399, value: 'Anden model', brandKey: 23 },
    { key: 400, value: 'S-Type', brandKey: 23 },
    { key: 401, value: 'X-type', brandKey: 23 },
    { key: 402, value: 'XF', brandKey: 23 },
    { key: 403, value: 'XJ', brandKey: 23 },
    { key: 404, value: 'XJ12', brandKey: 23 },
    { key: 405, value: 'XJ6', brandKey: 23 },
    { key: 406, value: 'XJ8', brandKey: 23 },
    { key: 407, value: 'XJR', brandKey: 23 },
    { key: 408, value: 'XK', brandKey: 23 },
    { key: 409, value: 'XK8', brandKey: 23 },
    { key: 410, value: 'XKR', brandKey: 23 },

    { key: 411, value: 'Anden model', brandKey: 24 },
    { key: 412, value: 'Cherokee', brandKey: 24 },
    { key: 413, value: 'Commander', brandKey: 24 },
    { key: 414, value: 'Grand Cherokee', brandKey: 24 },
    { key: 415, value: 'Wrangler', brandKey: 24 },

    { key: 416, value: 'Anden model', brandKey: 25 },
    { key: 417, value: 'Carens', brandKey: 25 },
    { key: 418, value: 'Carnival', brandKey: 25 },
    { key: 419, value: 'Ceed', brandKey: 25 },
    { key: 420, value: 'Cerato', brandKey: 25 },
    { key: 421, value: 'Clarus', brandKey: 25 },
    { key: 422, value: 'Magentis', brandKey: 25 },
    { key: 423, value: 'Opirus', brandKey: 25 },
    { key: 424, value: 'Picanto', brandKey: 25 },
    { key: 425, value: 'Pride', brandKey: 25 },
    { key: 426, value: 'Pro-Ceed', brandKey: 25 },
    { key: 427, value: 'Rio', brandKey: 25 },
    { key: 428, value: 'Sephia', brandKey: 25 },
    { key: 429, value: 'Shuma', brandKey: 25 },
    { key: 430, value: 'Sorento', brandKey: 25 },
    { key: 431, value: 'Soul', brandKey: 25 },
    { key: 432, value: 'Sportage', brandKey: 25 },
    { key: 433, value: 'Venga', brandKey: 25 },

    { key: 434, value: '111', brandKey: 26 },
    { key: 435, value: 'Anden model', brandKey: 26 },
    { key: 436, value: 'niva', brandKey: 26 },
    { key: 437, value: 'Samara', brandKey: 26 },

    { key: 438, value: 'Anden model', brandKey: 27 },
    { key: 439, value: 'Gallardo', brandKey: 27 },
    { key: 440, value: 'Murciélago', brandKey: 27 },

    { key: 441, value: 'Anden model', brandKey: 28 },
    { key: 442, value: 'Kappa', brandKey: 28 },
    { key: 443, value: 'Lybra', brandKey: 28 },
    { key: 444, value: 'Thesis', brandKey: 28 },
    { key: 445, value: 'Ypsilon', brandKey: 28 },
    { key: 446, value: 'Zeta', brandKey: 28 },

    { key: 447, value: 'Anden model', brandKey: 29 },
    { key: 448, value: 'Defender', brandKey: 29 },
    { key: 449, value: 'Discovery', brandKey: 29 },
    { key: 450, value: 'Discovery 3', brandKey: 29 },
    { key: 451, value: 'Discovery 4', brandKey: 29 },
    { key: 452, value: 'Freelander', brandKey: 29 },
    { key: 453, value: 'Freelander 2', brandKey: 29 },
    { key: 454, value: 'Range Rover', brandKey: 29 },
    { key: 455, value: 'Range Rover evoque', brandKey: 29 },
    { key: 456, value: 'Range Rover sport', brandKey: 29 },

    { key: 457, value: 'Anden model', brandKey: 30 },
    { key: 458, value: 'Maxus', brandKey: 30 },

    { key: 459, value: 'Anden model', brandKey: 31 },
    { key: 460, value: 'GS300', brandKey: 31 },
    { key: 461, value: 'GS430', brandKey: 31 },
    { key: 462, value: 'GS450H', brandKey: 31 },
    { key: 463, value: 'IS200', brandKey: 31 },
    { key: 464, value: 'IS200d', brandKey: 31 },
    { key: 465, value: 'IS220d', brandKey: 31 },
    { key: 466, value: 'IS250', brandKey: 31 },
    { key: 467, value: 'LS400', brandKey: 31 },
    { key: 468, value: 'LS430', brandKey: 31 },
    { key: 469, value: 'LS600h', brandKey: 31 },
    { key: 470, value: 'RX300', brandKey: 31 },
    { key: 471, value: 'RX350', brandKey: 31 },
    { key: 472, value: 'RX450h', brandKey: 31 },

    { key: 473, value: 'Anden model', brandKey: 32 },
    { key: 474, value: 'Elise', brandKey: 32 },
    { key: 475, value: 'Esprit', brandKey: 32 },

    { key: 476, value: '424', brandKey: 33 },
    { key: 477, value: 'Anden model', brandKey: 33 },
    { key: 478, value: 'Granturismo', brandKey: 33 },
    { key: 479, value: 'Quattroporte', brandKey: 33 },

    { key: 480, value: '121', brandKey: 34 },
    { key: 481, value: '2', brandKey: 34 },
    { key: 482, value: '3', brandKey: 34 },
    { key: 483, value: '323', brandKey: 34 },
    { key: 484, value: '323C', brandKey: 34 },
    { key: 485, value: '323F', brandKey: 34 },
    { key: 486, value: '323S', brandKey: 34 },
    { key: 487, value: '5', brandKey: 34 },
    { key: 488, value: '6', brandKey: 34 },
    { key: 489, value: '626', brandKey: 34 },
    { key: 490, value: 'Anden model', brandKey: 34 },
    { key: 491, value: 'CX-5', brandKey: 34 },
    { key: 492, value: 'CX-7', brandKey: 34 },
    { key: 493, value: 'CX-9', brandKey: 34 },
    { key: 494, value: 'Demio', brandKey: 34 },
    { key: 495, value: 'MPV', brandKey: 34 },
    { key: 496, value: 'MX3', brandKey: 34 },
    { key: 497, value: 'MX5', brandKey: 34 },
    { key: 498, value: 'MX6', brandKey: 34 },
    { key: 499, value: 'Premacy', brandKey: 34 },
    { key: 500, value: 'RX7', brandKey: 34 },
    { key: 501, value: 'RX8', brandKey: 34 },

    { key: 502, value: '190D', brandKey: 35 },
    { key: 503, value: '190E', brandKey: 35 },
    { key: 504, value: '420SEC', brandKey: 35 },
    { key: 505, value: '500SEC', brandKey: 35 },
    { key: 506, value: '560SEC', brandKey: 35 },
    { key: 507, value: '600SEC', brandKey: 35 },
    { key: 508, value: 'A140', brandKey: 35 },
    { key: 509, value: 'A150', brandKey: 35 },
    { key: 510, value: 'A160', brandKey: 35 },
    { key: 511, value: 'A170', brandKey: 35 },
    { key: 512, value: 'A180', brandKey: 35 },
    { key: 513, value: 'A200', brandKey: 35 },
    { key: 514, value: 'C180', brandKey: 35 },
    { key: 515, value: 'C200', brandKey: 35 },
    { key: 516, value: 'C220', brandKey: 35 },
    { key: 517, value: 'C230', brandKey: 35 },
    { key: 518, value: 'C240', brandKey: 35 },
    { key: 519, value: 'C250', brandKey: 35 },
    { key: 520, value: 'C270', brandKey: 35 },
    { key: 521, value: 'C280', brandKey: 35 },
    { key: 522, value: 'C30 AMG', brandKey: 35 },
    { key: 523, value: 'C320', brandKey: 35 },
    { key: 524, value: 'C350', brandKey: 35 },
    { key: 525, value: 'C55 AMG', brandKey: 35 },
    { key: 526, value: 'C63 AMG', brandKey: 35 },
    { key: 527, value: 'CL500', brandKey: 35 },
    { key: 528, value: 'CL600', brandKey: 35 },
    { key: 529, value: 'CL65 AMG', brandKey: 35 },
    { key: 530, value: 'CLK200', brandKey: 35 },
    { key: 531, value: 'CLK230', brandKey: 35 },
    { key: 532, value: 'CLK240', brandKey: 35 },
    { key: 533, value: 'CLK270', brandKey: 35 },
    { key: 534, value: 'CLK280', brandKey: 35 },
    { key: 535, value: 'CLK320', brandKey: 35 },
    { key: 536, value: 'CLK350', brandKey: 35 },
    { key: 537, value: 'CLK430', brandKey: 35 },
    { key: 538, value: 'CLK500', brandKey: 35 },
    { key: 539, value: 'CLK55 AMG', brandKey: 35 },
    { key: 540, value: 'CLK63 AMG', brandKey: 35 },
    { key: 541, value: 'CLS 55 AMG', brandKey: 35 },
    { key: 542, value: 'CLS 63 AMG', brandKey: 35 },
    { key: 543, value: 'CLS320', brandKey: 35 },
    { key: 544, value: 'CLS350', brandKey: 35 },
    { key: 545, value: 'CLS500', brandKey: 35 },
    { key: 546, value: 'E200', brandKey: 35 },
    { key: 547, value: 'E220', brandKey: 35 },
    { key: 548, value: 'E230', brandKey: 35 },
    { key: 549, value: 'E240', brandKey: 35 },
    { key: 550, value: 'E250', brandKey: 35 },
    { key: 551, value: 'E260', brandKey: 35 },
    { key: 552, value: 'E270', brandKey: 35 },
    { key: 553, value: 'E280', brandKey: 35 },
    { key: 554, value: 'E290', brandKey: 35 },
    { key: 555, value: 'E300', brandKey: 35 },
    { key: 556, value: 'E300', brandKey: 35 },
    { key: 557, value: 'E320', brandKey: 35 },
    { key: 558, value: 'E350', brandKey: 35 },
    { key: 559, value: 'E400', brandKey: 35 },
    { key: 560, value: 'E420', brandKey: 35 },
    { key: 561, value: 'E430', brandKey: 35 },
    { key: 562, value: 'E500', brandKey: 35 },
    { key: 563, value: 'E55 AMG', brandKey: 35 },
    { key: 564, value: 'E63 AMG', brandKey: 35 },
    { key: 565, value: 'G350', brandKey: 35 },
    { key: 566, value: 'GL320', brandKey: 35 },
    { key: 567, value: 'GL420', brandKey: 35 },
    { key: 568, value: 'GL450', brandKey: 35 },
    { key: 569, value: 'GLA220', brandKey: 35 },
    { key: 570, value: 'GLK220', brandKey: 35 },
    { key: 571, value: 'GLK250', brandKey: 35 },
    { key: 572, value: 'GLK320', brandKey: 35 },
    { key: 573, value: 'ML230', brandKey: 35 },
    { key: 574, value: 'ML270', brandKey: 35 },
    { key: 575, value: 'ML280', brandKey: 35 },
    { key: 576, value: 'ML320', brandKey: 35 },
    { key: 577, value: 'ML350', brandKey: 35 },
    { key: 578, value: 'ML400', brandKey: 35 },
    { key: 579, value: 'ML420', brandKey: 35 },
    { key: 580, value: 'ML500', brandKey: 35 },
    { key: 581, value: 'ML55 AMG', brandKey: 35 },
    { key: 582, value: 'ML63 AMG', brandKey: 35 },
    { key: 583, value: 'R280', brandKey: 35 },
    { key: 584, value: 'R320', brandKey: 35 },
    { key: 585, value: 'R350', brandKey: 35 },
    { key: 586, value: 'R500', brandKey: 35 },
    { key: 587, value: 'R63 AMG', brandKey: 35 },
    { key: 588, value: 'S250', brandKey: 35 },
    { key: 589, value: 'S300', brandKey: 35 },
    { key: 590, value: 'S320', brandKey: 35 },
    { key: 591, value: 'S350', brandKey: 35 },
    { key: 592, value: 'S400', brandKey: 35 },
    { key: 593, value: 'S420', brandKey: 35 },
    { key: 594, value: 'S430', brandKey: 35 },
    { key: 595, value: 'S500', brandKey: 35 },
    { key: 596, value: 'S55 AMG', brandKey: 35 },
    { key: 597, value: 'S600', brandKey: 35 },
    { key: 598, value: 'S63 AMG', brandKey: 35 },
    { key: 599, value: 'SL300', brandKey: 35 },
    { key: 600, value: 'SL320', brandKey: 35 },
    { key: 601, value: 'SL500', brandKey: 35 },
    { key: 602, value: 'SL55 AMG', brandKey: 35 },
    { key: 603, value: 'SL63 AMG', brandKey: 35 },
    { key: 604, value: 'SLK 55 AMG', brandKey: 35 },
    { key: 605, value: 'SLK200', brandKey: 35 },
    { key: 606, value: 'SLK230', brandKey: 35 },
    { key: 607, value: 'SLK280', brandKey: 35 },
    { key: 608, value: 'SLK320', brandKey: 35 },
    { key: 609, value: 'SLK350', brandKey: 35 },
    { key: 610, value: 'Sprinter', brandKey: 35 },
    { key: 611, value: 'V220', brandKey: 35 },
    { key: 612, value: 'V230', brandKey: 35 },
    { key: 613, value: 'V280', brandKey: 35 },
    { key: 614, value: 'Vaneo', brandKey: 35 },
    { key: 615, value: 'Viano', brandKey: 35 },
    { key: 616, value: 'Vito', brandKey: 35 },

    { key: 617, value: 'Anden model', brandKey: 36 },
    { key: 618, value: 'LeSabre', brandKey: 36 },

    { key: 619, value: 'Anden model', brandKey: 37 },
    { key: 620, value: 'F', brandKey: 37 },
    { key: 621, value: 'TF', brandKey: 37 },
    { key: 622, value: 'ZT', brandKey: 37 },
    { key: 623, value: 'ZT-T', brandKey: 37 },

    { key: 624, value: 'Anden model', brandKey: 38 },
    { key: 625, value: 'Clubman', brandKey: 38 },
    { key: 626, value: 'Clubman Cooper S', brandKey: 38 },
    { key: 627, value: 'Clubman One', brandKey: 38 },
    { key: 628, value: 'Cooper', brandKey: 38 },
    { key: 629, value: 'Cooper Cabriolet', brandKey: 38 },
    { key: 630, value: 'Cooper S', brandKey: 38 },
    { key: 631, value: 'Cooper S Cabriolet', brandKey: 38 },
    { key: 632, value: 'Countryman', brandKey: 38 },
    { key: 633, value: 'Countryman Cooper', brandKey: 38 },
    { key: 634, value: 'Countryman One', brandKey: 38 },
    { key: 635, value: 'One', brandKey: 38 },
    { key: 636, value: 'One Cabriolet', brandKey: 38 },

    { key: 638, value: '3000', brandKey: 39 },
    { key: 639, value: 'Anden model', brandKey: 39 },
    { key: 640, value: 'ASX', brandKey: 39 },
    { key: 641, value: 'Carisma', brandKey: 39 },
    { key: 642, value: 'Colt', brandKey: 39 },
    { key: 643, value: 'Eclipse', brandKey: 39 },
    { key: 644, value: 'Galant', brandKey: 39 },
    { key: 645, value: 'Grand Pajero', brandKey: 39 },
    { key: 646, value: 'Grandis', brandKey: 39 },
    { key: 647, value: 'Lancer', brandKey: 39 },
    { key: 648, value: 'Outlander', brandKey: 39 },
    { key: 649, value: 'Pajero', brandKey: 39 },
    { key: 650, value: 'Space Gear', brandKey: 39 },
    { key: 651, value: 'Space Star', brandKey: 39 },
    { key: 652, value: 'Space Wagon', brandKey: 39 },

    { key: 653, value: '4/4', brandKey: 40 },
    { key: 654, value: 'Anden model', brandKey: 40 },
    { key: 655, value: 'Plus 4', brandKey: 40 },
    { key: 656, value: 'Plus 8', brandKey: 40 },

    { key: 657, value: 'P1000', brandKey: 41 },

    { key: 658, value: '100 NX', brandKey: 42 },
    { key: 659, value: '200 SX', brandKey: 42 },
    { key: 660, value: '300 ZX', brandKey: 42 },
    { key: 661, value: '350Z', brandKey: 42 },
    { key: 662, value: '370Z', brandKey: 42 },
    { key: 663, value: 'Almera', brandKey: 42 },
    { key: 664, value: 'Almera Tino', brandKey: 42 },
    { key: 665, value: 'Anden model', brandKey: 42 },
    { key: 666, value: 'Juke', brandKey: 42 },
    { key: 667, value: 'King', brandKey: 42 },
    { key: 668, value: 'Maxima', brandKey: 42 },
    { key: 669, value: 'Micra', brandKey: 42 },
    { key: 670, value: 'Micra C+C', brandKey: 42 },
    { key: 671, value: 'Murano', brandKey: 42 },
    { key: 672, value: 'Note', brandKey: 42 },
    { key: 673, value: 'NV200', brandKey: 42 },
    { key: 674, value: 'Pathfinder', brandKey: 42 },
    { key: 675, value: 'Patrol', brandKey: 42 },
    { key: 676, value: 'Pixo', brandKey: 42 },
    { key: 677, value: 'Primastar', brandKey: 42 },
    { key: 678, value: 'Primera', brandKey: 42 },
    { key: 679, value: 'Qashqai', brandKey: 42 },
    { key: 680, value: 'Qashqai +2', brandKey: 42 },
    { key: 681, value: 'Serena', brandKey: 42 },
    { key: 682, value: 'Silvia', brandKey: 42 },
    { key: 683, value: 'Skyline', brandKey: 42 },
    { key: 684, value: 'Sunny', brandKey: 42 },
    { key: 685, value: 'Terrano', brandKey: 42 },
    { key: 686, value: 'X-Trail', brandKey: 42 },

    { key: 687, value: 'Adam', brandKey: 43 },
    { key: 688, value: 'Agila', brandKey: 43 },
    { key: 689, value: 'Ampera', brandKey: 43 },
    { key: 690, value: 'Anden model', brandKey: 43 },
    { key: 691, value: 'Antara', brandKey: 43 },
    { key: 692, value: 'Astra', brandKey: 43 },
    { key: 693, value: 'Astra Cabriolet', brandKey: 43 },
    { key: 694, value: 'Astra Classic', brandKey: 43 },
    { key: 695, value: 'Calibra', brandKey: 43 },
    { key: 695, value: 'Combo', brandKey: 43 },
    { key: 696, value: 'Corsa', brandKey: 43 },
    { key: 697, value: 'Frontera', brandKey: 43 },
    { key: 698, value: 'GT', brandKey: 43 },
    { key: 699, value: 'Insignia', brandKey: 43 },
    { key: 700, value: 'Kadett', brandKey: 43 },
    { key: 701, value: 'Meriva', brandKey: 43 },
    { key: 702, value: 'Movano', brandKey: 43 },
    { key: 703, value: 'Omega', brandKey: 43 },
    { key: 704, value: 'Signum', brandKey: 43 },
    { key: 705, value: 'Sintra', brandKey: 43 },
    { key: 706, value: 'Speedster', brandKey: 43 },
    { key: 707, value: 'Tigra', brandKey: 43 },
    { key: 708, value: 'Vectra', brandKey: 43 },
    { key: 709, value: 'Vivaro', brandKey: 43 },
    { key: 710, value: 'Zafira', brandKey: 43 },

    { key: 711, value: '1007', brandKey: 44 },
    { key: 712, value: '106', brandKey: 44 },
    { key: 713, value: '107', brandKey: 44 },
    { key: 714, value: '108', brandKey: 44 },
    { key: 715, value: '2008', brandKey: 44 },
    { key: 716, value: '205', brandKey: 44 },
    { key: 717, value: '206', brandKey: 44 },
    { key: 718, value: '206 CC', brandKey: 44 },
    { key: 719, value: '206 SW', brandKey: 44 },
    { key: 720, value: '206+', brandKey: 44 },
    { key: 721, value: '207', brandKey: 44 },
    { key: 722, value: '207 CC', brandKey: 44 },
    { key: 723, value: '207 Outdoor', brandKey: 44 },
    { key: 724, value: '207 RC', brandKey: 44 },
    { key: 725, value: '208', brandKey: 44 },
    { key: 726, value: '3008', brandKey: 44 },
    { key: 727, value: '306', brandKey: 44 },
    { key: 728, value: '306 Cabriolet', brandKey: 44 },
    { key: 729, value: '307', brandKey: 44 },
    { key: 730, value: '307 CC', brandKey: 44 },
    { key: 731, value: '308', brandKey: 44 },
    { key: 732, value: '308 CC', brandKey: 44 },
    { key: 733, value: '309', brandKey: 44 },
    { key: 734, value: '4007', brandKey: 44 },
    { key: 735, value: '405', brandKey: 44 },
    { key: 736, value: '406', brandKey: 44 },
    { key: 737, value: '406 Coupe', brandKey: 44 },
    { key: 738, value: '407', brandKey: 44 },
    { key: 739, value: '407 Coupe', brandKey: 44 },
    { key: 740, value: '5008', brandKey: 44 },
    { key: 741, value: '508', brandKey: 44 },
    { key: 742, value: '605', brandKey: 44 },
    { key: 743, value: '607', brandKey: 44 },
    { key: 744, value: '806', brandKey: 44 },
    { key: 745, value: '807', brandKey: 44 },
    { key: 746, value: 'Anden model', brandKey: 44 },
    { key: 747, value: 'Bipper', brandKey: 44 },
    { key: 748, value: 'Boxer', brandKey: 44 },
    { key: 749, value: 'Expert', brandKey: 44 },
    { key: 750, value: 'iON', brandKey: 44 },
    { key: 751, value: 'Partner', brandKey: 44 },
    { key: 752, value: 'Partner Tepee', brandKey: 44 },
    { key: 753, value: 'RCZ', brandKey: 44 },

    { key: 754, value: 'Anden model', brandKey: 45 },
    { key: 755, value: 'Firebird', brandKey: 45 },
    { key: 756, value: 'G6', brandKey: 45 },
    { key: 757, value: 'Trans Am', brandKey: 45 },

    { key: 758, value: '911', brandKey: 46 },
    { key: 759, value: '911 Carrera', brandKey: 46 },
    { key: 760, value: '911 Carrera 4', brandKey: 46 },
    { key: 761, value: '911 Carrera 4S', brandKey: 46 },
    { key: 762, value: '911 Carrera S', brandKey: 46 },
    { key: 763, value: '911 GT3', brandKey: 46 },
    { key: 764, value: '911 GT3 RS', brandKey: 46 },
    { key: 765, value: '911 Turbo', brandKey: 46 },
    { key: 766, value: '911', brandKey: 46 },
    { key: 767, value: '944', brandKey: 46 },
    { key: 768, value: 'Anden model', brandKey: 46 },
    { key: 769, value: 'Boxster', brandKey: 46 },
    { key: 770, value: 'Boxster S', brandKey: 46 },
    { key: 771, value: 'Cayenne', brandKey: 46 },
    { key: 772, value: 'Cayenne GTS', brandKey: 46 },
    { key: 773, value: 'Cayenne S', brandKey: 46 },
    { key: 774, value: 'Cayenne Turbo', brandKey: 46 },
    { key: 775, value: 'Cayman', brandKey: 46 },
    { key: 776, value: 'Cayman S', brandKey: 46 },
    { key: 777, value: 'Panamera 4S', brandKey: 46 },
    { key: 778, value: 'Panamera S', brandKey: 46 },
    { key: 779, value: 'Panamera Turbo', brandKey: 46 },

    { key: 780, value: '19', brandKey: 47 },
    { key: 781, value: 'Anden model', brandKey: 47 },
    { key: 782, value: 'Clio', brandKey: 47 },
    { key: 783, value: 'Clio II', brandKey: 47 },
    { key: 784, value: 'Clio III', brandKey: 47 },
    { key: 785, value: 'Clio IV', brandKey: 47 },
    { key: 786, value: 'Espace', brandKey: 47 },
    { key: 787, value: 'Fluence Z.E.', brandKey: 47 },
    { key: 788, value: 'Grand Espace', brandKey: 47 },
    { key: 789, value: 'Grand Modus', brandKey: 47 },
    { key: 790, value: 'Grand Scénic', brandKey: 47 },
    { key: 791, value: 'Grand Scénic II', brandKey: 47 },
    { key: 792, value: 'Grand Scénic III', brandKey: 47 },
    { key: 793, value: 'Kangoo', brandKey: 47 },
    { key: 794, value: 'Laguna', brandKey: 47 },
    { key: 795, value: 'Laguna II', brandKey: 47 },
    { key: 796, value: 'Laguna III', brandKey: 47 },
    { key: 797, value: 'Master T28', brandKey: 47 },
    { key: 798, value: 'Mégane', brandKey: 47 },
    { key: 799, value: 'Mégane Cabriolet', brandKey: 47 },
    { key: 800, value: 'Mégane II', brandKey: 47 },
    { key: 801, value: 'Mégane III', brandKey: 47 },
    { key: 802, value: 'Modus', brandKey: 47 },
    { key: 803, value: 'Safrane', brandKey: 47 },
    { key: 804, value: 'Scénic', brandKey: 47 },
    { key: 805, value: 'Scénic II', brandKey: 47 },
    { key: 806, value: 'Scénic III', brandKey: 47 },
    { key: 807, value: 'Trafic', brandKey: 47 },
    { key: 808, value: 'Twingo', brandKey: 47 },
    { key: 809, value: 'Vel Satis', brandKey: 47 },

    { key: 810, value: 'Anden model', brandKey: 48 },
    { key: 811, value: 'Silver Spur', brandKey: 48 },

    { key: 812, value: '200', brandKey: 49 },
    { key: 813, value: '214', brandKey: 49 },
    { key: 814, value: '216', brandKey: 49 },
    { key: 815, value: '25', brandKey: 49 },
    { key: 816, value: '400', brandKey: 49 },
    { key: 817, value: '416', brandKey: 49 },
    { key: 818, value: '420', brandKey: 49 },
    { key: 819, value: '45', brandKey: 49 },
    { key: 820, value: '620', brandKey: 49 },
    { key: 821, value: '75', brandKey: 49 },
    { key: 822, value: '820', brandKey: 49 },
    { key: 823, value: '827', brandKey: 49 },
    { key: 824, value: 'Anden model', brandKey: 49 },

    { key: 825, value: '9-3', brandKey: 50 },
    { key: 826, value: '9-3 Cabriolet', brandKey: 50 },
    { key: 827, value: '9-3X', brandKey: 50 },
    { key: 828, value: '9-5', brandKey: 50 },
    { key: 829, value: '900', brandKey: 50 },
    { key: 830, value: '900 Cabriolet', brandKey: 50 },
    { key: 831, value: '9000', brandKey: 50 },
    { key: 832, value: 'Anden model', brandKey: 50 },

    { key: 833, value: 'Alhambra', brandKey: 51 },
    { key: 834, value: 'Altea', brandKey: 51 },
    { key: 835, value: 'Altea XL', brandKey: 51 },
    { key: 836, value: 'Anden model', brandKey: 51 },
    { key: 837, value: 'Arosa', brandKey: 51 },
    { key: 838, value: 'Cordoba', brandKey: 51 },
    { key: 839, value: 'Exeo', brandKey: 51 },
    { key: 840, value: 'Ibiza', brandKey: 51 },
    { key: 841, value: 'Mii', brandKey: 51 },
    { key: 842, value: 'Toledo', brandKey: 51 },

    { key: 843, value: 'Anden model', brandKey: 52 },
    { key: 844, value: 'Citigo', brandKey: 52 },
    { key: 845, value: 'Fabia', brandKey: 52 },
    { key: 846, value: 'Favorit', brandKey: 52 },
    { key: 847, value: 'Felicia', brandKey: 52 },
    { key: 848, value: 'Octavia', brandKey: 52 },
    { key: 849, value: 'Octavia Scout', brandKey: 52 },
    { key: 850, value: 'Rapid', brandKey: 52 },
    { key: 851, value: 'Roomster', brandKey: 52 },
    { key: 852, value: 'Superb', brandKey: 52 },
    { key: 853, value: 'Yeti', brandKey: 52 },

    { key: 854, value: 'Anden model', brandKey: 53 },
    { key: 855, value: 'City Coupé', brandKey: 53 },
    { key: 856, value: 'City Coupé Cabrio', brandKey: 53 },
    { key: 857, value: 'Fortwo', brandKey: 53 },
    { key: 858, value: 'Fortwo Cabrio', brandKey: 53 },
    { key: 859, value: 'Fortwo Coupé', brandKey: 53 },
    { key: 860, value: 'Roadster', brandKey: 53 },

    { key: 861, value: 'Anden model', brandKey: 54 },
    { key: 862, value: 'Kyron', brandKey: 54 },
    { key: 863, value: 'Rexton', brandKey: 54 },
    { key: 864, value: 'Rexton II', brandKey: 54 },
    { key: 865, value: 'Rodius', brandKey: 54 },

    { key: 866, value: 'Anden model', brandKey: 55 },
    { key: 867, value: 'Forester', brandKey: 55 },
    { key: 868, value: 'Impreza', brandKey: 55 },
    { key: 869, value: 'Justy', brandKey: 55 },
    { key: 870, value: 'Legacy', brandKey: 55 },
    { key: 871, value: 'Libero', brandKey: 55 },
    { key: 872, value: 'Outback', brandKey: 55 },
    { key: 873, value: 'SVX', brandKey: 55 },
    { key: 874, value: 'XV', brandKey: 55 },

    { key: 875, value: 'Alto', brandKey: 56 },
    { key: 876, value: 'Anden model', brandKey: 56 },
    { key: 877, value: 'Baleno', brandKey: 56 },
    { key: 878, value: 'Grand Vitara', brandKey: 56 },
    { key: 879, value: 'Ignis', brandKey: 56 },
    { key: 880, value: 'Jimny', brandKey: 56 },
    { key: 881, value: 'Kizashi', brandKey: 56 },
    { key: 882, value: 'Liana', brandKey: 56 },
    { key: 883, value: 'Samurai', brandKey: 56 },
    { key: 884, value: 'Splash', brandKey: 56 },
    { key: 885, value: 'Swift', brandKey: 56 },
    { key: 886, value: 'SX4', brandKey: 56 },
    { key: 887, value: 'Vitara', brandKey: 56 },
    { key: 888, value: 'Wagon R', brandKey: 56 },
    { key: 889, value: 'Wagon R+', brandKey: 56 },

    { key: 890, value: 'Anden model model', brandKey: 57 },
    { key: 891, value: 'Roadster', brandKey: 57 },

    { key: 892, value: '4 Runner', brandKey: 58 },
    { key: 893, value: 'Anden model model', brandKey: 58 },
    { key: 894, value: 'Auris', brandKey: 58 },
    { key: 895, value: 'Avensis', brandKey: 58 },
    { key: 896, value: 'Avensis Verso', brandKey: 58 },
    { key: 897, value: 'Aygo', brandKey: 58 },
    { key: 898, value: 'Camry', brandKey: 58 },
    { key: 899, value: 'Carina', brandKey: 58 },
    { key: 900, value: 'Carina E', brandKey: 58 },
    { key: 901, value: 'Carina II', brandKey: 58 },
    { key: 902, value: 'Celica', brandKey: 58 },
    { key: 903, value: 'Corolla', brandKey: 58 },
    { key: 904, value: 'Corolla Sportsvan', brandKey: 58 },
    { key: 905, value: 'Corolla Verso', brandKey: 58 },
    { key: 906, value: 'HiAce', brandKey: 58 },
    { key: 907, value: 'HiLux', brandKey: 58 },
    { key: 908, value: 'iQ', brandKey: 58 },
    { key: 909, value: 'Landcruiser', brandKey: 58 },
    { key: 910, value: 'MR2', brandKey: 58 },
    { key: 911, value: 'Paseo', brandKey: 58 },
    { key: 912, value: 'Picnic', brandKey: 58 },
    { key: 913, value: 'Previa', brandKey: 58 },
    { key: 914, value: 'Prius', brandKey: 58 },
    { key: 915, value: 'RAV4', brandKey: 58 },
    { key: 916, value: 'Sports Van', brandKey: 58 },
    { key: 917, value: 'Starlet', brandKey: 58 },
    { key: 918, value: 'Supra', brandKey: 58 },
    { key: 919, value: 'Urban Cruiser', brandKey: 58 },
    { key: 920, value: 'Verso', brandKey: 58 },
    { key: 921, value: 'Verso-S', brandKey: 58 },
    { key: 922, value: 'Yaris', brandKey: 58 },
    { key: 923, value: 'Yaris Verso', brandKey: 58 },

    { key: 924, value: '240', brandKey: 59 },
    { key: 925, value: '440', brandKey: 59 },
    { key: 926, value: '460', brandKey: 59 },
    { key: 927, value: '480', brandKey: 59 },
    { key: 928, value: '740', brandKey: 59 },
    { key: 929, value: '760', brandKey: 59 },
    { key: 930, value: '780', brandKey: 59 },
    { key: 931, value: '850', brandKey: 59 },
    { key: 932, value: '940', brandKey: 59 },
    { key: 933, value: '960', brandKey: 59 },
    { key: 934, value: 'Anden model', brandKey: 59 },
    { key: 935, value: 'C30', brandKey: 59 },
    { key: 936, value: 'C70', brandKey: 59 },
    { key: 937, value: 'L495', brandKey: 59 },
    { key: 938, value: 'S40', brandKey: 59 },
    { key: 939, value: 'S60', brandKey: 59 },
    { key: 940, value: 'S70', brandKey: 59 },
    { key: 941, value: 'S80', brandKey: 59 },
    { key: 942, value: 'S90', brandKey: 59 },
    { key: 943, value: 'V40', brandKey: 59 },
    { key: 944, value: 'V50', brandKey: 59 },
    { key: 945, value: 'V60', brandKey: 59 },
    { key: 946, value: 'V70', brandKey: 59 },
    { key: 947, value: 'XC60', brandKey: 59 },
    { key: 948, value: 'XC70', brandKey: 59 },
    { key: 949, value: 'XC90', brandKey: 59 },

    { key: 950, value: 'Anden model', brandKey: 60 },
    { key: 951, value: 'Beetle', brandKey: 60 },
    { key: 952, value: 'Bora', brandKey: 60 },
    { key: 953, value: 'Caddy', brandKey: 60 },
    { key: 954, value: 'Caddy Maxi', brandKey: 60 },
    { key: 955, value: 'California', brandKey: 60 },
    { key: 956, value: 'Caravelle', brandKey: 60 },
    { key: 957, value: 'Corrado', brandKey: 60 },
    { key: 958, value: 'Crafter', brandKey: 60 },
    { key: 959, value: 'Eos', brandKey: 60 },
    { key: 960, value: 'Fox', brandKey: 60 },
    { key: 961, value: 'Golf', brandKey: 60 },
    { key: 962, value: 'Golf Cabriolet', brandKey: 60 },
    { key: 963, value: 'Golf II', brandKey: 60 },
    { key: 964, value: 'Golf III', brandKey: 60 },
    { key: 965, value: 'Golf IV', brandKey: 60 },
    { key: 966, value: 'Golf Plus', brandKey: 60 },
    { key: 967, value: 'Golf V', brandKey: 60 },
    { key: 968, value: 'Golf VI', brandKey: 60 },
    { key: 969, value: 'Jetta', brandKey: 60 },
    { key: 970, value: 'Lupo', brandKey: 60 },
    { key: 971, value: 'Multivan', brandKey: 60 },
    { key: 972, value: 'Passat', brandKey: 60 },
    { key: 973, value: 'Passat CC', brandKey: 60 },
    { key: 974, value: 'Phaeton', brandKey: 60 },
    { key: 975, value: 'Polo', brandKey: 60 },
    { key: 976, value: 'Polo Cross', brandKey: 60 },
    { key: 977, value: 'Scirocco', brandKey: 60 },
    { key: 978, value: 'Sharan', brandKey: 60 },
    { key: 979, value: 'Shuttle', brandKey: 60 },
    { key: 980, value: 'Tiguan', brandKey: 60 },
    { key: 981, value: 'Touareg', brandKey: 60 },
    { key: 982, value: 'Touran', brandKey: 60 },
    { key: 983, value: 'Touran Cross', brandKey: 60 },
    { key: 984, value: 'Transporter', brandKey: 60 },
    { key: 985, value: 'UP!', brandKey: 60 },
    { key: 986, value: 'Vento', brandKey: 60 },

    { key: 987, value: '1000', brandKey: 61 },
    { key: 988, value: '2+2 Coupe', brandKey: 61 },
    { key: 989, value: '353', brandKey: 61 },
    { key: 990, value: 'Anden model', brandKey: 61 },
    { key: 991, value: 'Sport', brandKey: 61 },

    { key: 992, value: 'POLO VAN', brandKey: 62 }
  ],
  MCAgeList: [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118],

  MCBrandList: ['Alfa Romeo', 'Alpina', 'Aston Martin', 'Audi', 'Bentley'
    , 'BMW', 'Cadillac', 'Chevrolet', 'Chrysler', 'Citroën'
    , 'Dacia', 'Daewoo', 'Daihatsu', 'Dodge', 'RENAULT'],

  MCModelList: ['Avanger', 'Caliber', 'TWINGO'],

  CampBrandList: ['Alfa Romeo', 'Alpina', 'Aston Martin', 'Audi', 'Bentley'
    , 'BMW', 'Cadillac', 'Chevrolet', 'Chrysler', 'Citroën'
    , 'Dacia', 'Daewoo', 'Daihatsu', 'Dodge', 'RENAULT'],

  CampModelList: ['Avanger', 'Caliber', 'TWINGO'],

  HestRace: [{ Text: "Aegidienberger", Value: "078" }
    , { Text: "Akhal-Tekiner", Value: "077" }
    , { Text: "American Curly", Value: "065" }
    , { Text: "American Painthorse", Value: "013" }
    , { Text: "American Quarterhorse", Value: "012" }
    , { Text: "American Saddle Bred", Value: "016" }
    , { Text: "Andalusier", Value: "028" }
    , { Text: "Angloaraber", Value: "003" }
    , { Text: "Appaloosa", Value: "048" }
    , { Text: "Arabisk Fuldblod", Value: "002" }
    , { Text: "Arabisk Halvblod", Value: "005" }
    , { Text: "Ardenner", Value: "023" }
    , { Text: "Barokpinto", Value: "088" }
    , { Text: "Basjkir", Value: "060" }
    , { Text: "Belgier", Value: "091" }
    , { Text: "Belgisk Varmblod", Value: "072" }
    , { Text: "Connemarapony", Value: "033" }
    , { Text: "Dansk Sportspony", Value: "074" }
    , { Text: "Dansk Varmblod", Value: "027" }
    , { Text: "Dartmoorpony", Value: "040" }
    , { Text: "Engelsk Fuldblod", Value: "001" }
    , { Text: "Exmoorpony", Value: "041" }
    , { Text: "Falabella", Value: "046" }
    , { Text: "Finsk Hest", Value: "025" }
    , { Text: "Fjordhest", Value: "024" }
    , { Text: "Frederiksborger", Value: "073" }
    , { Text: "Frieser", Value: "053" }
    , { Text: "Haflinger", Value: "032" }
    , { Text: "Hannoveraner", Value: "017" }
    , { Text: "Highland Pony", Value: "069" }
    , { Text: "Hollandsk Sportspony", Value: "081" }
    , { Text: "Hollandsk Varmblod (KWPN)", Value: "071" }
    , { Text: "Holstener", Value: "018" }
    , { Text: "Irish Cob", Value: "070" }
    , { Text: "Irsk Sportshest", Value: "057" }
    , { Text: "Irsk Sportspony", Value: "082" }
    , { Text: "Islænder", Value: "031" }
    , { Text: "Jysk Hest", Value: "076" }
    , { Text: "Knabstrupper", Value: "015" }
    , { Text: "Koldblodstraver", Value: "021" }
    , { Text: "Krydsning med Halvblod", Value: "008" }
    , { Text: "Krydsningspony", Value: "044" }
    , { Text: "Lipizzaner", Value: "006" }
    , { Text: "Lusitano", Value: "067" }
    , { Text: "Miniaturehest", Value: "085" }
    , { Text: "Miniknabstrupper", Value: "047" }
    , { Text: "Minishetland", Value: "039" }
    , { Text: "Morganhest", Value: "007" }
    , { Text: "Muldyr", Value: "101" }
    , { Text: "Mulæsel", Value: "102" }
    , { Text: "New Forest Pony", Value: "034" }
    , { Text: "Nordsvensk Brugshest", Value: "022" }
    , { Text: "Oldenborger", Value: "019" }
    , { Text: "Palomino (hest)", Value: "093" }
    , { Text: "Palomino (pony)", Value: "092" }
    , { Text: "Paso Fino", Value: "075" }
    , { Text: "Paso Peruano", Value: "079" }
    , { Text: "Pinto Hest", Value: "090" }
    , { Text: "Pinto Pony", Value: "089" }
    , { Text: "Polsk Halvblod", Value: "059" }
    , { Text: "Pura Raza Español (P.R.E.)", Value: "068" }
    , { Text: "Russ", Value: "030" }
    , { Text: "Shagyaaraber", Value: "014" }
    , { Text: "Shetlandspony", Value: "038" }
    , { Text: "Shire", Value: "061" }
    , { Text: "Svensk Ridpony", Value: "063" }
    , { Text: "Svensk Varmblod (SWB)", Value: "004" }
    , { Text: "Tennessee Walking Horse", Value: "049" }
    , { Text: "Tinker", Value: "066" }
    , { Text: "Tori", Value: "062" }
    , { Text: "Trakehner", Value: "026" }
    , { Text: "Tysk Sportspony", Value: "080" }
    , { Text: "Varmblodstraver", Value: "011" }
    , { Text: "Welsh Cob", Value: "037" }
    , { Text: "Welsh cob-type", Value: "042" }
    , { Text: "Welsh Mountain Pony", Value: "036" }
    , { Text: "Welsh Partbred (Hest)", Value: "084" }
    , { Text: "Welsh Partbred (Pony)", Value: "083" }
    , { Text: "Welshpony", Value: "035" }
    , { Text: "Westphaler", Value: "058" }
    , { Text: "Æsel", Value: "100" }
    , { Text: "Øvrige Fullblod", Value: "010" }
    , { Text: "Øvrige halvblod", Value: "009" }
    , { Text: "Øvrige Koldblod", Value: "029" }],

  SmadyrRace: [{ Text: "Afrikansk dværgpindsvin", Value: "025" }
    , { Text: "Chinchilla", Value: "006" }
    , { Text: "Degu", Value: "022" }
    , { Text: "Egyptisk langøret pindsvin", Value: "027" }
    , { Text: "Fugl", Value: "046", }
    , { Text: "Får", Value: "020" }
    , { Text: "Ged", Value: "017" }
    , { Text: "Gris", Value: "016" }
    , { Text: "Hamster", Value: "012" }
    , { Text: "Hængebugsvin", Value: "002" }
    , { Text: "Ilder", Value: "007" }
    , { Text: "Jordegern", Value: "030" }
    , { Text: "Kanin", Value: "004" }
    , { Text: "Marsvin", Value: "003" }
    , { Text: "Mus", Value: "014" }
    , { Text: "Reptil", Value: "008" }
    , { Text: "Rotte", Value: "005" }
    , { Text: "Skildpadde", Value: "011" }
    , { Text: "Slange", Value: "010" }
    , { Text: "Sugar Gliders", Value: "028" }
    , { Text: "Ørkenrotte", Value: "024" }
    , { Text: "Øvrige dyr", Value: "032" }],

  KatRace: [{ Text: "Huskatte", Value: "BLA" }
    , { Text: "Abyssinier", Value: "ABE" }
    , { Text: "American Curl", Value: "AME" }
    , { Text: "Asian", Value: "ASI" }
    , { Text: "Balineser", Value: "BAL" }
    , { Text: "Bengal", Value: "BEN" }
    , { Text: "Bombay", Value: "BOM" }
    , { Text: "British Shorthair", Value: "BRK" }
    , { Text: "Burmeser", Value: "BUR" }
    , { Text: "Burmilla", Value: "BUM" }
    , { Text: "Chartreux", Value: "CHA" }
    , { Text: "Chinchilla Perser", Value: "PCH" }
    , { Text: "Colorpoint (maskerade) Perser", Value: "PCO" }
    , { Text: "Cornish Rex", Value: "REC" }
    , { Text: "Devon Rex", Value: "RED" }
    , { Text: "Egyptiske Mau", Value: "EGY" }
    , { Text: "Europé", Value: "EUR" }
    , { Text: "Exotic", Value: "EXK" }
    , { Text: "Foreign White", Value: "FOR" }
    , { Text: "German Rex", Value: "REG" }
    , { Text: "Hellig Birma", Value: "BIR" }
    , { Text: "Japanese Bobtail", Value: "JBO" }
    , { Text: "Javaneser", Value: "JAV" }
    , { Text: "Korat", Value: "KOR" }
    , { Text: "Kurilean Bobtail", Value: "KUR" }
    , { Text: "LaPerm", Value: "LAP" }
    , { Text: "Lykoi", Value: "LYK" }
    , { Text: "Maine coon", Value: "MAC" }
    , { Text: "Manx", Value: "MAN" }
    , { Text: "Neva Masquerade", Value: "NEM" }
    , { Text: "Norsk skovkatte", Value: "NOS" }
    , { Text: "Ocicat", Value: "OCI" }
    , { Text: "Orientalisk korthår", Value: "ORK" }
    , { Text: "Orientalsk Langhår", Value: "ORL" }
    , { Text: "Perser", Value: "POV" }
    , { Text: "Peterbald", Value: "PET" }
    , { Text: "Pixie Bob", Value: "PIB" }
    , { Text: "Ragdoll", Value: "RAG" }
    , { Text: "Rex", Value: "REX" }
    , { Text: "Russian Blue", Value: "RUS" }
    , { Text: "Savannah", Value: "SAV" }
    , { Text: "Scottish Fold", Value: "SCO" }
    , { Text: "Selkirk Rex", Value: "RES" }
    , { Text: "Seychellios", Value: "SEY" }
    , { Text: "Siameser", Value: "SIA" }
    , { Text: "Sibirisk kat", Value: "SIB" }
    , { Text: "Snowshoe", Value: "SNO" }
    , { Text: "Sokoke", Value: "SOK" }
    , { Text: "Somali", Value: "SOM" }
    , { Text: "Sphynx", Value: "SPY" }
    , { Text: "Toyger", Value: "TG" }
    , { Text: "Tyrkisk Angora", Value: "TAN" }
    , { Text: "Tyrkisk Van", Value: "TUV" }
    , { Text: "Øvriga raser", Value: "KOV" }],

  HundRace: [
    { Text: "Blandingsrace", Value: "092" }
    , { Text: "Affenpinscher", Value: "201" }
    , { Text: "Afghansk Mynde", Value: "001" }
    , { Text: "Aïdi", Value: "254" }
    , { Text: "Airedale Terrier", Value: "301" }
    , { Text: "Akita", Value: "501" }
    , { Text: "Alaskan Husky", Value: "097" }
    , { Text: "Alaskan Malamute", Value: "502" }
    , { Text: "Alpenländische Dachsbracke", Value: "601" }
    , { Text: "American Akita", Value: "560" }
    , { Text: "American Eskimo Dog", Value: "562" }
    , { Text: "American Foxhound", Value: "602" }
    , { Text: "American Toy Fox Terrier", Value: "335" }
    , { Text: "Amerikansk Cocker Spaniel", Value: "802" }
    , { Text: "Amerikansk Vandspaniel", Value: "801" }
    , { Text: "Anatolsk Hyrdehunde", Value: "202" }
    , { Text: "Anglo-Français De Petite Venerie", Value: "603" }
    , { Text: "Anglo-Russkaja Gontjaja", Value: "661" }
    , { Text: "Appenzeller Sennenhund", Value: "203" }
    , { Text: "Ariegeois", Value: "604" }
    , { Text: "Australian Shepherd", Value: "102" }
    , { Text: "Australian Silky Terrier", Value: "324" }
    , { Text: "Australsk Cattle Dog", Value: "103" }
    , { Text: "Australsk Kelpie", Value: "104" }
    , { Text: "Australsk Terrier", Value: "303" }
    , { Text: "Azawakh", Value: "002" }
    , { Text: "Balkan Støver", Value: "605" }
    , { Text: "Barbet", Value: "803" }
    , { Text: "Basenji", Value: "503" }
    , { Text: "Basset Artesien Normand", Value: "606" }
    , { Text: "Basset Bleu De Gascogne", Value: "607" }
    , { Text: "Basset Fauve De Bretagne", Value: "609" }
    , { Text: "Basset Hound", Value: "610" }
    , { Text: "Bayersk Bjergschweisshund", Value: "611" }
    , { Text: "Beagle", Value: "612" }
    , { Text: "Beagle-Harrier", Value: "613" }
    , { Text: "Bearded Collie", Value: "105" }
    , { Text: "Beauceron", Value: "106" }
    , { Text: "Bedlington Terrier", Value: "304" }
    , { Text: "Berner Laufhunde", Value: "668" }
    , { Text: "Berner Sennenhund", Value: "204" }
    , { Text: "Bichon Frise", Value: "901" }
    , { Text: "Bichon Havanais", Value: "902" }
    , { Text: "Biewer Terrier", Value: "336" }
    , { Text: "Billy", Value: "614" }
    , { Text: "Black And Tan Coonhound", Value: "615" }
    , { Text: "Blanding m. Bulldog el. Mops", Value: "093" }
    , { Text: "Blodhund", Value: "623" }
    , { Text: "Bluetick coonhund", Value: "683" }
    , { Text: "Bolognese", Value: "903" }
    , { Text: "Border Collie", Value: "113" }
    , { Text: "Border Terrier", Value: "305" }
    , { Text: "Borzoi", Value: "003" }
    , { Text: "Bosnisk Støver, Ruhåret", Value: "616" }
    , { Text: "Boston Terrier", Value: "904" }
    , { Text: "Bouvier Des Ardennes", Value: "114" }
    , { Text: "Bouvier Des Flandres", Value: "115" }
    , { Text: "Boxer", Value: "206" }
    , { Text: "Bracco Italiano", Value: "701" }
    , { Text: "Brandlbracke", Value: "651" }
    , { Text: "Braque D'auvergne", Value: "702" }
    , { Text: "Braque De L'ariege", Value: "703" }
    , { Text: "Braque Du Bourbonnais", Value: "704" }
    , { Text: "Braque Francais Gascogne", Value: "706" }
    , { Text: "Braque Francais Pyrenees", Value: "707" }
    , { Text: "Braque Saint Germain", Value: "708" }
    , { Text: "Breton", Value: "709" }
    , { Text: "Briard", Value: "116" }
    , { Text: "Briquet Griffon Vendéen", Value: "618" }
    , { Text: "Broholmer", Value: "207" }
    , { Text: "Bull Terrier", Value: "306" }
    , { Text: "Bull Terrier, Miniature", Value: "318" }
    , { Text: "Bullmastiff", Value: "208" }
    , { Text: "Ca De Bestiar", Value: "117" }
    , { Text: "Cairn Terrier", Value: "307" }
    , { Text: "Cane Corso Italiano", Value: "255" }
    , { Text: "Cane Da Pastore Bergamasco", Value: "111" }
    , { Text: "Canaan Hund", Value: "504" }
    , { Text: "Cao Da Serra Da Estrela", Value: "221" }
    , { Text: "Cao Da Serra De Aires", Value: "118" }
    , { Text: "Cao De Agua Portugues", Value: "817" }
    , { Text: "Cao De Castro Laboreiro", Value: "209" }
    , { Text: "Cao Fila De Sao Miguel", Value: "256" }
    , { Text: "Cavalier King Charles Spaniel", Value: "905" }
    , { Text: "Ceskoslovenský Vlciak", Value: "119" }
    , { Text: "Cesky Terrier", Value: "308" }
    , { Text: "Chart Polski", Value: "004" }
    , { Text: "Chesapeake Bay Retriever", Value: "804" }
    , { Text: "Chien D'artois", Value: "619" }
    , { Text: "Chihuahua, Korthåret", Value: "906" }
    , { Text: "Chihuahua, Langhåret", Value: "907" }
    , { Text: "Chinese Crested/Powder Puff", Value: "993" }
    , { Text: "Chodsky pes", Value: "199" }
    , { Text: "Chow Chow", Value: "505" }
    , { Text: "Cimarron Uruguayo", Value: "258" }
    , { Text: "Clumber Spaniel", Value: "805" }
    , { Text: "Cockapoo", Value: "070" }
    , { Text: "Cocker Spaniel", Value: "806" }
    , { Text: "Cocker Spaniel-FT", Value: "A04" }
    , { Text: "Collie", Value: "121" }
    , { Text: "Collie, Korthåret", Value: "120" }
    , { Text: "Coton De Tulear", Value: "910" }
    , { Text: "Curly Coated Retriever", Value: "807" }
    , { Text: "Dalmatiner", Value: "678" }
    , { Text: "Dandie Dinmont Terrier", Value: "309" }
    , { Text: "Dansk Spids", Value: "563" }
    , { Text: "Dansk/Svensk Gårdhunde", Value: "210" }
    , { Text: "Deutsch Stichelhaar", Value: "733" }
    , { Text: "Deutsche Bracke", Value: "620" }
    , { Text: "Dobermann", Value: "211" }
    , { Text: "Dogo Canario", Value: "257" }
    , { Text: "Dogue De Bordeaux", Value: "205" }
    , { Text: "Drentsche Patrijshond", Value: "711" }
    , { Text: "Drever", Value: "621" }
    , { Text: "Dunker Støver", Value: "622" }
    , { Text: "Dværgpinscher", Value: "213" }
    , { Text: "Dværgschnauzer, Hvid", Value: "217" }
    , { Text: "Dværgschnauzer, Peber/Salt", Value: "214" }
    , { Text: "Dværgschnauzer, Sort", Value: "215" }
    , { Text: "Dværgschnauzer, Sort/Sølv", Value: "216" }
    , { Text: "Engelsk Bulldog", Value: "218" }
    , { Text: "Engelsk Foxhound", Value: "626" }
    , { Text: "Engelsk Setter", Value: "712" }
    , { Text: "Engelsk Springer Spaniel", Value: "808" }
    , { Text: "Engelsk Springer Spaniel-FT", Value: "A03" }
    , { Text: "Engelsk Toy Terrier", Value: "310" }
    , { Text: "Entlebucher Sennenhund", Value: "219" }
    , { Text: "Epagneul Bleu De Picardie", Value: "713" }
    , { Text: "Epagneul De Pont-Audemer", Value: "714" }
    , { Text: "Epagneul Francais", Value: "715" }
    , { Text: "Epagneul Picard", Value: "716" }
    , { Text: "Estlandsstøver", Value: "680" }
    , { Text: "Etnahund", Value: "506" }
    , { Text: "Eurasier", Value: "507" }
    , { Text: "Field Spaniel", Value: "809" }
    , { Text: "Finsk Hyrdehunde", Value: "518" }
    , { Text: "Finsk Lapphund", Value: "508" }
    , { Text: "Finsk Spids", Value: "509" }
    , { Text: "Finsk Støver", Value: "625" }
    , { Text: "Flat Coated Retriever", Value: "810" }
    , { Text: "Fox Terrier,glathåret", Value: "311" }
    , { Text: "Fox Terrier,ruhåret", Value: "312" }
    , { Text: "Français Blanc Et Noir", Value: "627" }
    , { Text: "Français Blanc Et Orange", Value: "628" }
    , { Text: "Français Tricolore", Value: "629" }
    , { Text: "Fransk Bulldog", Value: "913" }
    , { Text: "Galgo Espanol", Value: "006" }
    , { Text: "Gammel Dansk Hønsehund", Value: "717" }
    , { Text: "Golden Retriever", Value: "811" }
    , { Text: "Gordon Setter", Value: "718" }
    , { Text: "Gos D'atura Catala", Value: "122" }
    , { Text: "Gotlandsstøver", Value: "630" }
    , { Text: "Grand Anglo-Français Blanc Et Noir", Value: "631" }
    , { Text: "Grand Anglo-Français Blanc Et Orange", Value: "632" }
    , { Text: "Grand Anglo-Français Tricolore", Value: "633" }
    , { Text: "Grand Basset Griffon Vendeen", Value: "634" }
    , { Text: "Grand Bleu De Gascogne", Value: "635" }
    , { Text: "Grand Danois", Value: "223" }
    , { Text: "Grand Gascon Saintongeois", Value: "636" }
    , { Text: "Grand Griffon Vendeen", Value: "637" }
    , { Text: "Greyhound", Value: "007" }
    , { Text: "Griffon Belge", Value: "914" }
    , { Text: "Griffon Belge/Bruxellois", Value: "991" }
    , { Text: "Griffon Bleu De Gascogne", Value: "638" }
    , { Text: "Griffon Bruxellois", Value: "915" }
    , { Text: "Griffon Fauve De Bretagne", Value: "639" }
    , { Text: "Griffon Korthals", Value: "720" }
    , { Text: "Griffon Nivernais", Value: "640" }
    , { Text: "Groenendael", Value: "107" }
    , { Text: "Grosser Münsterländer", Value: "734" }
    , { Text: "Grosser Schweizer Sennenhund", Value: "224" }
    , { Text: "Gross-Spitz", Value: "536" }
    , { Text: "Græsk Støver", Value: "645" }
    , { Text: "Grønlandshund", Value: "510" }
    , { Text: "Halden Støver", Value: "641" }
    , { Text: "Hamiltonstøver", Value: "642" }
    , { Text: "Hannoveransk Schweisshund", Value: "643" }
    , { Text: "Harrier", Value: "644" }
    , { Text: "Hjortehund", Value: "019" }
    , { Text: "Hokkaido", Value: "511" }
    , { Text: "Hollandsk Hyrdehund,korthåret", Value: "123" }
    , { Text: "Hollandsk Hyrdehund,langhåret", Value: "124" }
    , { Text: "Hollandsk Hyrdehund,ruhåret", Value: "125" }
    , { Text: "Hollandsk Smoushond", Value: "225" }
    , { Text: "Hovawart", Value: "226" }
    , { Text: "Hvid Hyrdehunde", Value: "090" }
    , { Text: "Hvid Schweizisk Hyrdehunde", Value: "198" }
    , { Text: "Hygenhunde", Value: "646" }
    , { Text: "Hælleforshund", Value: "599" }
    , { Text: "Irish Glen Of Imaal Terrier", Value: "313" }
    , { Text: "Irish Softcoated Wheaten Terrier", Value: "327" }
    , { Text: "Irish Water Spaniel", Value: "812" }
    , { Text: "Irsk Rød/Hvid Setter", Value: "721" }
    , { Text: "Irsk Setter", Value: "722" }
    , { Text: "Irsk Terrier", Value: "314" }
    , { Text: "Irsk Ulvehund", Value: "008" }
    , { Text: "Islandsk Fårehund", Value: "512" }
    , { Text: "Istrisk Støver, Korthåret", Value: "647" }
    , { Text: "Istrisk Støver, Ruhåret", Value: "648" }
    , { Text: "Italiensk Mynde", Value: "009" }
    , { Text: "Jack Russell Terrier", Value: "340" }
    , { Text: "Japanese", Value: "916" }
    , { Text: "Japansk Spids", Value: "513" }
    , { Text: "Japansk Terrier", Value: "319" }
    , { Text: "Jura Laufhund", Value: "669" }
    , { Text: "Jämthunde", Value: "514" }
    , { Text: "Kai", Value: "515" }
    , { Text: "Karelsk Bjørnehund", Value: "516" }
    , { Text: "Karst Hyrdehund", Value: "252" }
    , { Text: "Keeshond", Value: "532" }
    , { Text: "Kerry Blue Terrier", Value: "315" }
    , { Text: "King Charles Spaniel", Value: "917" }
    , { Text: "Kishu", Value: "517" }
    , { Text: "Kleiner Münsterländer", Value: "724" }
    , { Text: "Kleinspitz", Value: "533" }
    , { Text: "Komondor", Value: "128" }
    , { Text: "Kooikerhondje", Value: "813" }
    , { Text: "Korea Jindo Dog", Value: "546" }
    , { Text: "Korthåret Dværg Gravhund", Value: "402" }
    , { Text: "Korthåret Gravhund", Value: "408" }
    , { Text: "Korthåret Hønsehund", Value: "723" }
    , { Text: "Korthåret Miniature Gravhund", Value: "405" }
    , { Text: "Kroatisk Hyrdehunde", Value: "126" }
    , { Text: "Kromfohrländer", Value: "918" }
    , { Text: "Kuvasz", Value: "130" }
    , { Text: "Labradoodle", Value: "081" }
    , { Text: "Labrador Retriever", Value: "814" }
    , { Text: "Laekenois", Value: "108" }
    , { Text: "Lagotto Romagnolo", Value: "822" }
    , { Text: "Lakeland Terrier", Value: "316" }
    , { Text: "Lancashire Heeler", Value: "131" }
    , { Text: "Landseer", Value: "228" }
    , { Text: "Langhåret Dværg Gravhund", Value: "403" }
    , { Text: "Langhåret Gravhund", Value: "409" }
    , { Text: "Langhåret Hønsehund", Value: "725" }
    , { Text: "Langhåret Miniature Gravhund", Value: "406" }
    , { Text: "Leonberger", Value: "229" }
    , { Text: "Lhasa Apso", Value: "919" }
    , { Text: "Lille Berner Støver", Value: "665" }
    , { Text: "Lille Jura Støver", Value: "666" }
    , { Text: "Lille Luzerner Støver", Value: "667" }
    , { Text: "Lille Schwyzer Støver", Value: "664" }
    , { Text: "Luzerner Laufhund", Value: "698" }
    , { Text: "Löwchen", Value: "920" }
    , { Text: "Magyar Agar", Value: "010" }
    , { Text: "Malinois", Value: "109" }
    , { Text: "Mallorca Mastiff/Ca De Bou", Value: "238" }
    , { Text: "Malteser", Value: "921" }
    , { Text: "Maltipoo", Value: "071" }
    , { Text: "Manchester Terrier", Value: "317" }
    , { Text: "Maremma", Value: "132" }
    , { Text: "Mastiff", Value: "230" }
    , { Text: "Mastin De Los Pirineos", Value: "240" }
    , { Text: "Mastin Espanol", Value: "231" }
    , { Text: "Mastino Napoletano", Value: "235" }
    , { Text: "Miniature American Shepherd", Value: "155" }
    , { Text: "Mittelspitz", Value: "534" }
    , { Text: "Montenegrinsk Bjergstøver", Value: "649" }
    , { Text: "Mops", Value: "925" }
    , { Text: "Mudi", Value: "133" }
    , { Text: "Newfoundland", Value: "236" }
    , { Text: "Norfolk Terrier", Value: "320" }
    , { Text: "Norrbottenspids", Value: "519" }
    , { Text: "Norsk Buhund", Value: "520" }
    , { Text: "Norsk Elghund, Grå", Value: "522" }
    , { Text: "Norsk Elghund, Sort", Value: "523" }
    , { Text: "Norsk Lundehunde", Value: "521" }
    , { Text: "Norwich Terrier", Value: "321" }
    , { Text: "Nova Scotia Duck Tolling Retriever", Value: "815" }
    , { Text: "Odderhund", Value: "653" }
    , { Text: "Ogar Polski", Value: "652" }
    , { Text: "Old Eng. Bulldog (ej godk iht FCI)", Value: "083" }
    , { Text: "Old English Sheepdog", Value: "134" }
    , { Text: "Papillon", Value: "926" }
    , { Text: "Parson Russell Terrier", Value: "322" }
    , { Text: "Pekingeser", Value: "927" }
    , { Text: "Perdigueiro Portugues", Value: "726" }
    , { Text: "Perdiguero De Burgos", Value: "727" }
    , { Text: "Perro De Agua Espanol", Value: "816" }
    , { Text: "Peruviansk Hårløs,lille", Value: "545" }
    , { Text: "Peruviansk Hårløs,mellem", Value: "544" }
    , { Text: "Peruviansk Hårløs,stor", Value: "543" }
    , { Text: "Petit Basset Griffon Vendeen", Value: "654" }
    , { Text: "Petit Bleu De Gascogne", Value: "655" }
    , { Text: "Petit Brabancon", Value: "931" }
    , { Text: "Petit Gascon Saintongeois", Value: "656" }
    , { Text: "Phalene", Value: "932" }
    , { Text: "Pharaoh Hound", Value: "547" }
    , { Text: "Picard", Value: "112" }
    , { Text: "Plott", Value: "682" }
    , { Text: "Podenco Canario", Value: "548" }
    , { Text: "Podenco Ibicenco, Korthåret", Value: "550" }
    , { Text: "Podenco Ibicenco, Ruhåret", Value: "549" }
    , { Text: "Podengo Portugues,korthåret Dværg", Value: "556" }
    , { Text: "Podengo Portugues,korthåret Mellem", Value: "555" }
    , { Text: "Podengo Portugues,korthåret Stor", Value: "554" }
    , { Text: "Podengo Portugues,ruhåret Dværg", Value: "553" }
    , { Text: "Podengo Portugues,ruhåret Mellem", Value: "552" }
    , { Text: "Podengo Portugues,ruhåret Stor", Value: "551" }
    , { Text: "Pointer", Value: "728" }
    , { Text: "Poitevin", Value: "657" }
    , { Text: "Polsk Støver", Value: "681" }
    , { Text: "Polski Owczarek Nizinny", Value: "135" }
    , { Text: "Polski Owczarek Podhalanski", Value: "136" }
    , { Text: "Pomeranian", Value: "535" }
    , { Text: "Porcelaine", Value: "658" }
    , { Text: "Posavski Støver", Value: "659" }
    , { Text: "Prazsky Krysarik", Value: "941" }
    , { Text: "Pudel,dværg", Value: "912" }
    , { Text: "Pudel,mellem", Value: "922" }
    , { Text: "Pudel,stor", Value: "935" }
    , { Text: "Pudel,toy", Value: "938" }
    , { Text: "Pudelpointer", Value: "729" }
    , { Text: "Puggle", Value: "075" }
    , { Text: "Puli", Value: "137" }
    , { Text: "Pumi", Value: "138" }
    , { Text: "Pyreneerhund", Value: "239" }
    , { Text: "Pyrenæisk Hyrdehund", Value: "139" }
    , { Text: "Pyrenæisk Hyrdehund M.Korthårshoved", Value: "140" }
    , { Text: "Rafeiro Do Alentejo", Value: "241" }
    , { Text: "Rat Terrier", Value: "343" }
    , { Text: "Rhodesian Ridgeback", Value: "679" }
    , { Text: "Riesenschnauzer, Peber/Salt", Value: "242" }
    , { Text: "Riesenschnauzer, Sort", Value: "243" }
    , { Text: "Rottweiler", Value: "244" }
    , { Text: "Ruhåret Dværg Gravhund", Value: "404" }
    , { Text: "Ruhåret Gravhund", Value: "410" }
    , { Text: "Ruhåret Hønsehund", Value: "735" }
    , { Text: "Ruhåret Miniature Gravhund", Value: "407" }
    , { Text: "Rumænsk Hyrdehund Carpatin", Value: "153" }
    , { Text: "Rumænsk Hyrdehund Mioritic", Value: "152" }
    , { Text: "Russisk Toy,korthåret", Value: "996" }
    , { Text: "Russisk Toy,langhåret", Value: "995" }
    , { Text: "Russisk-Europæisk Laika", Value: "524" }
    , { Text: "Russkaja Gontjaja", Value: "660" }
    , { Text: "Russkaja Tsvetnaya Bolonka", Value: "942" }
    , { Text: "Sabueso Espanol", Value: "662" }
    , { Text: "Saluki, Glathåret", Value: "018" }
    , { Text: "Saluki, Langhåret", Value: "017" }
    , { Text: "Samojedhunde", Value: "525" }
    , { Text: "Sankt Bernhardshund, Korthåret", Value: "245" }
    , { Text: "Sankt Bernhardshund, Langhåret", Value: "246" }
    , { Text: "Schapendoes", Value: "143" }
    , { Text: "Schiller Støver", Value: "663" }
    , { Text: "Schipperke", Value: "144" }
    , { Text: "Schnauzer, Peber/Salt", Value: "233" }
    , { Text: "Schnauzer, Sort", Value: "234" }
    , { Text: "Schwyzer Laufhund", Value: "671" }
    , { Text: "Schæferhund", Value: "145" }
    , { Text: "Schæferhund, langstockhåret", Value: "196" }
    , { Text: "Sealyham Terrier", Value: "323" }
    , { Text: "Segugio, Korthåret", Value: "672" }
    , { Text: "Segugio, Ruhåret", Value: "673" }
    , { Text: "Serbisk Støver, Tricolor", Value: "650" }
    , { Text: "Shar Pei", Value: "247" }
    , { Text: "Shetland Sheepdog", Value: "146" }
    , { Text: "Shiba", Value: "526" }
    , { Text: "Shih Tzu", Value: "934" }
    , { Text: "Shikoku", Value: "527" }
    , { Text: "Siberian Husky", Value: "528" }
    , { Text: "Skotsk Terrier", Value: "325" }
    , { Text: "Skye Terrier", Value: "326" }
    , { Text: "Sloughi", Value: "020" }
    , { Text: "Slovakisk Ruhår", Value: "730" }
    , { Text: "Slovakisk Støver", Value: "674" }
    , { Text: "Smålandsstøver", Value: "675" }
    , { Text: "Sort Russisk Terrier", Value: "249" }
    , { Text: "Sort Spids", Value: "564" }
    , { Text: "Spinone", Value: "731" }
    , { Text: "Stabyhoun", Value: "732" }
    , { Text: "Staffordshire Bull Terrier", Value: "328" }
    , { Text: "Steirische Rauhhaarbracke", Value: "676" }
    , { Text: "Sussex Spaniel", Value: "818" }
    , { Text: "Svensk Lapphunde", Value: "529" }
    , { Text: "Svensk Vit Elghund", Value: "530" }
    , { Text: "Saarlooswolfhond", Value: "141" }
    , { Text: "Taiwan Dog", Value: "557" }
    , { Text: "Tenterfield terrier", Value: "334" }
    , { Text: "Terrier Brazileiro", Value: "333" }
    , { Text: "Tervueren", Value: "110" }
    , { Text: "Thai Ridgeback Dog", Value: "531" }
    , { Text: "Tibetansk Mastiff", Value: "250" }
    , { Text: "Tibetansk Spaniel", Value: "936" }
    , { Text: "Tibetansk Terrier", Value: "937" }
    , { Text: "Tiroler Bracke", Value: "617" }
    , { Text: "Tjekkisk Ruhåret Hønsehund", Value: "710" }
    , { Text: "Transylvansk Støver", Value: "624" }
    , { Text: "Tysk Jagtterrier", Value: "329" }
    , { Text: "Tysk Pinscher", Value: "232" }
    , { Text: "Tysk Wachtelhund", Value: "819" }
    , { Text: "Vestsibirisk Laika", Value: "539" }
    , { Text: "Vizsla,korthåret", Value: "736" }
    , { Text: "Vizsla,ruhåret", Value: "737" }
    , { Text: "Volpino Italiano", Value: "537" }
    , { Text: "Västgötaspids", Value: "538" }
    , { Text: "Weimaraner, Korthåret", Value: "738" }
    , { Text: "Weimaraner, Langhåret", Value: "739" }
    , { Text: "Welsh Corgi Cardigan", Value: "148" }
    , { Text: "Welsh Corgi Pembroke", Value: "149" }
    , { Text: "Welsh Springer Spaniel", Value: "820" }
    , { Text: "Welsh Terrier", Value: "330" }
    , { Text: "West Highland White Terrier", Value: "331" }
    , { Text: "Westfälische Dachsbracke", Value: "677" }
    , { Text: "Wetterhoun", Value: "821" }
    , { Text: "Whippet", Value: "021" }
    , { Text: "Working Kelpie", Value: "197" }
    , { Text: "Xoloitzcuintle sin/con Pelo, Mellem", Value: "559" }
    , { Text: "Xoloitzcuintle sin/con Pelo, Miniature", Value: "541" }
    , { Text: "Xoloitzcuintle sin/con Pelo, Standard", Value: "542" }
    , { Text: "Yorkshire Terrier", Value: "332" }
    , { Text: "Østrigsk Pinscher", Value: "237" }
    , { Text: "Østsibirisk Laika", Value: "540" }
  ]

};


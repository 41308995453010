import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AddressService } from '../address/address.service';
const addresses = ['Moldaugade', 'Moldrupsvej', 'Moldevej 9, st. th, 7100 Vejle', ' Fashion road'];

@Component({
  selector: 'app-change-of-ownership',
  templateUrl: './change-of-ownership.component.html',
  styleUrls: ['./change-of-ownership.component.scss']
})
export class ChangeOfOwnershipComponent implements OnInit {
  @ViewChild('changeOwnershipForm', { read: NgForm, static: false })
  changeOwnershipForm: NgForm;
  addressForm: NgForm;
  @Output() next = new EventEmitter<any>();
  @Input() resetChangeOwnershipFormSubject: Subject<any> = new Subject<any>();
  address: string;
  addressId: any;
  model: any = {};
  addressList: any = [];
  livingSpace: any = [
    { key: 110, value: 'Stuehus til landbrugsejendom' },
    { key: 120, value: 'Fritliggende enfamiliehus' },
    { key: 130, value: 'Rækkehus eller dobbelthuse' },
    { key: 140, value: 'Etageboligbebyggelse' },
    { key: 510, value: 'Sommerhus' }
  ];
  hvilkenList: any = ['Sælger', 'Køber', 'Ejendomsmægler', 'Andet'];
  //boligTypeList: any = ['Hus', 'Rækkehus', 'Landejendom', 'Nedlagt landbrug', 'Anden boligtype'];
  years: any = [];
  constructor(private addressService: AddressService) { }
  resultFormatBandListValue(value: any) {
    return value.tekst;
  }
  disp(): string[] {
    var currentYear = new Date().getFullYear(), years = [];
    let startYear = 1800;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }
  ngOnInit() {
    this.years = this.disp();
    // const maxYear = new Date().getFullYear();
    // const minYear = 1800;

    // for (let i = maxYear; i > minYear; i--) {
    //     this.years.push(i);
    // }
  }

  checkInputLength() {
    if (this.address.length) {
      this.getAddressDetails();
    } else {
      return;
    }
  }

  getAddressDetails() {
    this.addressService.getAddressDetail(this.address)
      .subscribe((res: any) => {
        this.addressList = [];
        if (res.data.data) {
          this.addressList = res.data.data;
        }
      }, error => {

      });
  }

  getSelectedItem(event) {
    this.model = event.item.adresse;
    this.model.search = event.item;
    this.addressId = '';
    if (this.model) {
      this.addressId = this.model.id;
      this.getAddressDetailsById(this.addressId);
    }
  }

  getAddressDetailsById(id) {
    this.addressService.getAddressDetailsById(id)
      .subscribe((res: any) => {
        if (res && res.data.data) {
          const responseDetail = res.data.data[0];
          if (responseDetail.bygning.TAG_KODE === 1) {
            this.model.tagType = 2;
          } else if (responseDetail.bygning.TAG_KODE === 7) {
            this.model.tagType = 3;
          } else if (responseDetail.bygning.TAG_KODE === 11 || responseDetail.bygning.TAG_KODE === 12
              || responseDetail.bygning.TAG_KODE === 90) {
            this.model.tagType = 4;
          } else if (responseDetail.bygning.TAG_KODE === 2 || responseDetail.bygning.TAG_KODE === 3
            || responseDetail.bygning.TAG_KODE === 3 || responseDetail.bygning.TAG_KODE === 4
            || responseDetail.bygning.TAG_KODE === 5 || responseDetail.bygning.TAG_KODE === 6) {
          this.model.tagType = 1;
        }
          // this.model.tagType = responseDetail.bygning.TAG_KODE;
          this.model.year = responseDetail.bygning.OPFOERELSE_AAR;
          this.model.housingType = responseDetail.ENH_ANVEND_KODE;
          this.model.place = responseDetail.bygning.BYG_BOLIG_ARL_SAML;
        }
      }, error => {

      });
  }
  formSubmit(): void {
    //if (this.changeOwnershipForm.valid) {
      this.next.emit(this.model);
    //}
  }

  // checkInputLength() {
  //   if (this.address.length >= 4) {
  //     // this.getAddressDetails();
  //   } else {
  //     return;
  //   }
  // }

  // getSelectedItem(event) {
  //   this.model = event.item.adresse;
  // }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 0 ? []
      : this.addressList.filter(v => v.tekst.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common/common.service';
import { urlConstant } from '../constants/UrlConstant';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(public commonService: CommonService, public http: HttpClient) { }

  getAddressDetail(addres: any) {
    const model: any  = { address: addres };
    return this.commonService.post(urlConstant.Address.getAddressDetail, JSON.stringify(model))
      .pipe(map((x: Response) => x));
  }

  getAddressDetailsById(id: any) {
    return this.commonService.get(urlConstant.Address.getAddressDetailsById + '?Id=' + id)
      .pipe(map((x: Response) => x));
  }

}

import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { VehicleService } from './vehicle.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OfferService } from '../offer/offer.service';
import { Subject } from 'rxjs';
import { dataConstant } from '../constant/dataConstant';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as data from '../../assets/application.json';
import * as _ from 'underscore';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit, OnDestroy {
  applicationData: any;
  label:any;
  searchText: string;
  isGotData = false;
  brandList: any;
  isValidType = null;
  modelList: any;
  allModelList: any;
  engineSizeList = ['0.5 liter', '0.6 liter', '0.6 liter', '0.7 liter', '0.8 liter', '0.9 liter', '1.0 liter', '1.1 liter',
    '1.2 liter', '1.3 liter', '1.4 liter', '1.5 liter', '1.6 liter', '1.7 liter', '1.8 liter',
    '1.9 liter', '2.0 liter', '2.1 liter', '2.2 liter', '2.3 liter', '2.4 liter', '2.5 liter', '2.6 liter', '2.7 liter'
    , '2.8 liter', '2.9 liter', '3.0 liter', '3.1 liter', '3.2 liter', '3.3 liter', '3.4 liter', '3.5 liter', '3.6 liter',
    '3.7 liter', '3.8 liter', '3.9 liter', '4.0 liter', '4.1 liter', '4.2 liter', '4.2 liter', '4.3 liter', '4.4 liter'
    , '4.5 liter', '4.6 liter', '4.7 liter', '4.8 liter', '4.9 liter', '5.0 liter'
    , '5.1 liter', '5.2 liter', '5.3 liter', '5.4 liter', '5.5 liter', '5.6 liter', '5.7 liter'
    , '5.8 liter', '5.9 liter', '6.0 liter'];
  yearList = [];
  noOfYearsList = ['0', '1', '2', '3', '4', '6', '6', '7', '8', '9', '10', 'More than 10', 'Dont know'];
  validType = ['Personbil'];
  brand: string;
  model: string;
  engineSize: string;
  year: string;
  otherInformation: string;
  yearWithOwnCar: string;
  dropdownList = [];
  selectedItems = [];
  modelData = {
    id: 1,
    brand: '',
    model: '',
    engineSize: '',
    year: '',
    otherInformation: '',
    yearWithOwnCar: '',
    eleName: '',
    isCompleted: false,
    selectedItems: [],
    number: ''
  };
  dropdownSettings: IDropdownSettings;
  @ViewChild('vehicleForm', { read: NgForm, static: false }) vehicleForm: NgForm;
  @Input() resetVehicleFormSubject: Subject<any> = new Subject<any>();
  // @Input() selected = new Object();
  @Input() defautKeyValue: any;
  @Output() next = new EventEmitter<any>();

  constructor(private router: Router, private vehicleService: VehicleService,
    private offerService: OfferService, private cd: ChangeDetectorRef) { 
      this.applicationData = data;
    }

  ngOnInit() {
    this.modelData = {
      id: 1, brand: this.brand, model: this.model,
      engineSize: this.engineSize, year: this.year,
      otherInformation: this.otherInformation, yearWithOwnCar: this.yearWithOwnCar,
      eleName: '', isCompleted: false,
      selectedItems: [],
      number: ''
    };

    this.dropdownList = [
      { item_id: 1, item_text: 'Ansvarsforsikring' },
      { item_id: 2, item_text: 'Kaskoforsikring' }
    ];
    this.selectedItems = [
      { item_id: 1, item_text: 'Ansvarsforsikring' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Vælg alt',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.resetVehicleFormSubject.pipe(debounceTime(10), distinctUntilChanged()).subscribe(response => {
      console.log('resetVehicleFormSubject response : ', response);
      if (response) {
        const element = localStorage.getItem(response.name);
        console.log('element : ', element);

        if (element) {
          const data = JSON.parse(element);
          setTimeout(() => {
            this.modelData = data;
            this.isGotData = true;
            console.log('modelData : ', this.modelData);
          }, 1000);
          console.log('modelData : ', this.modelData);

          this.cd.markForCheck();

        } else if (this.modelData.eleName !== response.name && response.isCompleted) {
          this.modelData.eleName = response.name;
          this.modelData.isCompleted = response.isCompleted;
          localStorage.setItem(response.name, JSON.stringify(this.modelData));
        } else {
          this.vehicleForm.reset();
          this.isGotData = false;
          this.searchText = '';
        }
      }
    });
    this.brandList = dataConstant.brandList;
    this.allModelList = dataConstant.modelList;
    this.modelData.selectedItems = this.selectedItems;

    const maxYear = new Date().getFullYear();
    const minYear = 1900;

    for (let i = maxYear; i > minYear; i--) {
        this.yearList.push(i);
    }
  }

  ngAfterViewInit() {
    this.vehicleForm.statusChanges.subscribe((result) => {
      if (result === 'VALID') {
        this.offerService.setBillValidationObs(true);
      } else {
        this.offerService.setBillValidationObs(false);
      }
      //     console.log(result)
    });


  }
  ngOnChanges(changes): void {
    if (changes.defautKeyValue) {
      this.SetResetVehicleFormSubject(this.defautKeyValue);
      console.log(this.defautKeyValue);
    }
  }

  ngOnDestroy(): void {
    this.cd.detach();
  }

  checkInputLength() {
    this.isValidType = null;
    this.isGotData = false;
    if (this.searchText.length > 4) {
      this.getVehicleDetails();
    } else {
      return;
    }
  }


  getVehicleDetails() {
    this.vehicleService.getVehicleDetail(this.searchText)
      .subscribe((res: any) => {
        if (res.data.data) {
          if(res.data.data.type && this.validType.indexOf(res.data.data.type) > -1){
          this.isValidType = true;
          this.brandList = [];
          this.allModelList = [];
          const modellst = dataConstant.modelList
          this.brandList = dataConstant.brandList;
          this.allModelList = dataConstant.modelList;
          this.modelData.brand = res.data.data.make;
          this.modelData.model = res.data.data.model;
          if (this.modelData.brand) {
            const brandname = this.modelData.brand;
            const isExist = this.brandList.find(item => item.value === brandname);
            if (isExist) {
              this.modelData.brand = isExist.key;
              this.ChangeVehicleBrand(isExist.key);
            }
            else {
              const brandkey = (this.brandList.length + 1);
              const modelKey = (this.allModelList.length + 1);
              const objBrand = { key: brandkey, value: this.modelData.brand };
              this.brandList.push(objBrand);

              const objmodel = { key: modelKey, value: this.modelData.model, brandKey: brandkey };
              this.allModelList.push(objmodel);
              this.modelData.brand = brandkey;
              // this.ngAfterViewInit();
              this.ChangeVehicleBrand(brandkey);
            }
          }
          this.modelData.engineSize = 'Ved ikke';
          if (res.data.data.engine_volume) {
            this.modelData.engineSize = (res.data.data.engine_volume / 1000).toFixed(1) + ' liter';
          }
          if(res.data.data.model_year){
            this.modelData.year = res.data.data.model_year;
          }
          const enzinesize = this.modelData.engineSize;
          const isExistEngineSize = this.engineSizeList.find(item => item === enzinesize);
          if (!isExistEngineSize) {
            this.engineSizeList.push(enzinesize);
          }
          this.isGotData = true;
        }
        else{
          this.isValidType = false;
        }
        } else {
          this.isValidType = false;
          this.isGotData = true;
        }
      }, error => {

      });
  }

  ChangeVehicleBrand(brandkey) {
    if (brandkey) {
      const isExist = this.brandList.find(item => item.key === brandkey);
      if (isExist) {
        this.modelList = this.allModelList.filter(item => item.brandKey === isExist.key);
      }
    }
    if (this.modelData.model) {
      const isExistModel = this.modelList.find(item => item.value.toLowerCase() === this.modelData.model.toLowerCase());
      if (isExistModel) {
        this.modelData.brand = brandkey;
        this.modelData.model = isExistModel.key;
      }
    }
  }

  formSubmit(): void {
    if (this.vehicleForm.valid) {
      this.modelData.number = this.searchText;
      this.next.emit(this.modelData);
    }
  }

  SetResetVehicleFormSubject(response) {
    if (response) {
      const element = localStorage.getItem(response.name);
      if (element) {
        const data = JSON.parse(element);
        setTimeout(() => {
          this.searchText = data.number;
          this.modelData = data;
          if(data.coverage){
            this.modelData.selectedItems = [];
            var converage = data.coverage.split(',');
            _.each(converage,element => {
              this.modelData.selectedItems.push(_.find(this.dropdownList, {item_text: element}));
            })
          }
          this.getVehicleDetails();
          this.isGotData = true;
        }, 1000);
      }
    }
  }
}

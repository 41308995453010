import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common/common.service';
import { urlConstant } from '../constants/UrlConstant';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(public commonService: CommonService, public http: HttpClient) { }

  getVehicleDetail(plateNum) {
    const model = { vehicleNumber: plateNum };
    return this.commonService.post(urlConstant.Vehicle.GetVehicleDetail, JSON.stringify(model))
      .pipe(map((x: Response) => x));
  }

}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss']
})
export class TripComponent implements OnInit {
  @ViewChild('travelForm', { read: NgForm, static: false })
  travelForm: NgForm;
  @Output() next = new EventEmitter<any>();
  @Input() resetTravelFormSubject: Subject<any> = new Subject<any>();

  model: any = {};
  onskerList = ['Europa', 'Verden', 'Ved ikke'];
  hvillkenList = ['Årsrejseforsikring', 'Enkeltrejseforsikring'];
  antalVoksneList = [];
  antalBornList = [];
  constructor() { }

  ngOnInit() {
    this.setAntalVoksneAndBorn();
  }

  setAntalVoksneAndBorn() {
    let data = [];
    data.push('Ved ikke');
    for (let i = 0; i<=9;i++) {
      data.push(i);
    }
    data.push('Flere end 9');
    this.antalVoksneList = data;
    this.antalBornList = data;
  }

  formSubmit(): void {
    if (this.travelForm.valid) {
       this.next.emit(this.model);
    }
  }
}

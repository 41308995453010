import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})

export class CommonService {
  // public loginUser = localStorage.getItem('loginUser');
  public config = {};
  public userData: any = {};
  public loading: any;

  constructor(
    public http: HttpClient,
    private toastr: ToastrService,
    public router: Router
  ) { }

  private prepareHeader(headers: HttpHeaders | null): object {
    headers = headers || new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    return {
      headers: headers
    }
  }

  get<T>(url: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get<T>(url, expandedHeaders)
  }

  post(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.post(url, body, expandedHeaders);
  }

  public handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    //  console.log(errMsg);
    return Observable.throw(errMsg);
  }

  public isValidDate(day: number, month: number, year: number) {
    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12)
      return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  public getUserOffset() {
    return new Date().getTimezoneOffset();
  }

  public getUserDate() {
    return (moment(new Date()).format('MM/DD/YYYY  HH:mm:ss'));
  }

  public getUserTime() {
    return (moment(new Date()).format('HH:mm:ss'));
  }
  // use for not allow special  character in autocomplete - *,(,),+,?,\
  eventHandler(event: any) {
    if (event.keyCode == 92 || event.keyCode == 42 || event.keyCode == 40 || event.keyCode == 41 || event.keyCode == 43 || event.keyCode == 63 || event.keyCode == 91 || event.keyCode == 93) {
      return false;
    }
  }



  public toastSuccessMsg(title: any, message: any, timeOut?: object) {
    this.toastr.success(title, message, timeOut);
  }

  public toastErrorMsg(title: any, message: any, timeOut?: object) {
    this.toastr.error(title, message, timeOut);
  }

  public toastWarningMsg(title: any, message: any, timeOut?: object) {
    this.toastr.warning(title, message, timeOut);
  }



  // Allow only numeric value.
  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // Allow number with decimal
  public onlyDotsAndNumbers(event, txt): boolean {
    const charCode = (event.which) ? event.which : event.keyCode
    if (charCode === 46) {
      if (txt.indexOf('.') < 0) {
        return true;
      } else {
        return false;
      }
    }
    if (txt && txt.indexOf('.') > 0) {
      const txtlen = txt.length;
      const dotpos = txt.indexOf('.');
      if ((txtlen - dotpos) > 2){
        return false;
      }
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getLoggedInUserId() {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user')).Id;
    }
    return null;
  }

  getLoggedInUser() {
    if (localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('user'));
    }
    return null;
  }
}


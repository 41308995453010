import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { urlConstant } from '../constants/UrlConstant';
@Injectable({
  providedIn: 'root'
})
export class OfferService {

  private billValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private addressValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private accidentalValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private mcValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private boatValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private travelValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private campingValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private lifeValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private holingHomeValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private changeOwnershipValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private animalValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private indboValidationObs$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(public commonService: CommonService) {
  }

  getBillValidationObs(): Observable<boolean> {
    return this.billValidationObs$.asObservable();
  }

  setBillValidationObs(validate: boolean) {
    this.billValidationObs$.next(validate);
  }

  getAddressValidationObs(): Observable<boolean> {
    return this.addressValidationObs$.asObservable();
  }
  setAddressValidationObs(validate: boolean) {
    this.addressValidationObs$.next(validate);
  }

  getAccidentalValidationObs(): Observable<boolean> {
    return this.accidentalValidationObs$.asObservable();
  }
  setAccidentalValidationObs(validate: boolean) {
    this.accidentalValidationObs$.next(validate);
  }

  getMCValidationObs(): Observable<boolean> {
    return this.mcValidationObs$.asObservable();
  }
  setMCValidationObs(validate: boolean) {
    this.mcValidationObs$.next(validate);
  }

  setBoatValidationObs(validate: boolean) {
    this.boatValidationObs$.next(validate);
  }

  getBoatValidationObs(): Observable<boolean> {
    return this.boatValidationObs$.asObservable();
  }

  setTravelValidationObs(validate: boolean) {
    this.travelValidationObs$.next(validate);
  }

  getTravelValidationObs(): Observable<boolean> {
    return this.travelValidationObs$.asObservable();
  }

  setCampingValidationObs(validate: boolean) {
    this.campingValidationObs$.next(validate);
  }

  getCampingValidationObs(): Observable<boolean> {
    return this.campingValidationObs$.asObservable();
  }

  setLifeValidationObs(validate: boolean) {
    this.lifeValidationObs$.next(validate);
  }

  getLifeValidationObs(): Observable<boolean> {
    return this.lifeValidationObs$.asObservable();
  }

  setHolingHomeValidationObs(validate: boolean) {
    this.holingHomeValidationObs$.next(validate);
  }

  getHolingHomeValidationObs(): Observable<boolean> {
    return this.holingHomeValidationObs$.asObservable();
  }

  setChangeOwnershipValidationObs(validate: boolean) {
    this.changeOwnershipValidationObs$.next(validate);
  }

  getChangeOwnershipValidationObs(): Observable<boolean> {
    return this.changeOwnershipValidationObs$.asObservable();
  }

  setAnimalValidationObs(validate: boolean) {
    this.animalValidationObs$.next(validate);
  }

  getAnimalValidationObs(): Observable<boolean> {
    return this.animalValidationObs$.asObservable();
  }

  getIndboValidationObs(): Observable<boolean> {
    return this.indboValidationObs$.asObservable();
  }

  setIndboValidationObs(validate: boolean) {
    this.indboValidationObs$.next(validate);
  }

  saveInquiry(model: any) {
    return this.commonService.post(urlConstant.Inquiry.saveInquiry, JSON.stringify(model))
    .pipe(map((x: Response) => x));
  }

  getTestimonial() {
    return this.commonService.get(urlConstant.Testimonial.GetList)
    .pipe(map((x: Response) => x));
  }

}

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { MCAtvMopedService } from "./mcatv-moped.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { OfferService } from "../offer/offer.service";
import { Subject } from "rxjs";
import { dataConstant } from "../constant/dataConstant";

@Component({
  selector: "app-mcatv-moped",
  templateUrl: "./mcatv-moped.component.html",
  styleUrls: ["./mcatv-moped.component.scss"],
})
export class MCAtvMopedComponent implements OnInit {
  searchText: string;
  isGotData = false;
  isValidType = true;
  brandList = dataConstant.MCBrandList;
  modelList = dataConstant.MCModelList;
  ageList = dataConstant.MCAgeList;
  engineSizeList = [
    "0,5 liter",
    "0,6 liter",
    "0,7 liter",
    "0,8 liter",
    "0,9 liter",
    "1,0 liter",
    "1,1 liter",
    "1149",
  ];
  yearList = [
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
  ];
  noOfYearsList = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "More than 10",
    "Dont know",
  ];
  noOfKMsList = [
    "0 - 5.000 km",
    "5.000 - 10.000 km",
    "10.000 - 15.000 km",
    "15.000 - 20.000 km",
    "20.000 - 25.000 km",
    "Mere end 25.000 km",
  ];
  validType = ["Campingvogn", "Motorcykel"];
  vehicalType = [
    "MC",
    "MC Veteran",
    "Scooter",
    "ATV",
    "Knallert 30",
    "Knallert 45",
    "Andet",
  ];
  brandmodel = "";
  brand = "";
  model = "";
  engineSize = "";
  year = "";
  relInfo = "";
  noOfYear = "";
  dropdownList = [];
  selectedItems = [];
  modelData = {
    id: 1,
    number: "",
    brand: this.brand,
    model: this.model,
    brandmodel: this.brandmodel,
    engineSize: this.engineSize,
    year: this.year,
    relInfo: this.relInfo,
    noOfYear: this.noOfYear,
    eleName: "",
    isCompleted: false,
  };
  dropdownSettings: IDropdownSettings;
  @ViewChild("mcForm", { read: NgForm, static: false })
  mcForm: NgForm;
  @Output() next = new EventEmitter<any>();
  @Input() resetMcFormSubject: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private mcAtvMopedService: MCAtvMopedService,
    private offerService: OfferService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: "Egen garage" },
      { item_id: 2, item_text: "Fælles garage" },
      { item_id: 3, item_text: "På gaden" },
      { item_id: 4, item_text: "Parkeringskælder" },
      { item_id: 5, item_text: "Andet" },
    ];
    this.selectedItems = [{ item_id: 1, item_text: "Ansvarsforsikring" }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Vælg alt",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.resetMcFormSubject.subscribe((response) => {
      if (response) {
        var element = localStorage.getItem(response.name);
        if (element) {
          var data = JSON.parse(element);
          this.modelData = data;
          this.isGotData = true;
          this.cd.detectChanges();
        } else if (
          this.modelData.eleName != response.name &&
          response.isCompleted
        ) {
          this.modelData.eleName = response.name;
          this.modelData.isCompleted = response.isCompleted;
          localStorage.setItem(response.name, JSON.stringify(this.modelData));
        } else {
          this.mcForm.reset();
          this.isGotData = false;
          this.searchText = "";
        }
      }
    });
  }

  ngAfterViewInit() {
    this.mcForm.statusChanges.subscribe((result) => {
      if (result === "VALID") {
        this.offerService.setMCValidationObs(true);
      }
      //     console.log(result)
    });
  }

  checkInputLength() {
    this.isValidType = true;
    if (this.searchText.length > 4) {
      this.getMCDetails();
    } else {
      return;
    }
  }

  getMCDetails() {
    this.mcAtvMopedService.getMcDetails(this.searchText).subscribe(
      (res: any) => {
        if (res.data.data) {
          if (
            res.data.data.type &&
            this.validType.indexOf(res.data.data.type) > -1
          ) {
            this.insertBrand(res.data.data.make);
            this.insertModel(res.data.data.model);
            this.modelData.brand = res.data.data.make;
            this.modelData.brandmodel =
              res.data.data.make + " " + res.data.data.model;
            this.modelData.model = res.data.data.model;
            this.modelData.engineSize = res.data.data.engine_volume;
            this.modelData.year = res.data.data.model_year;
            this.isGotData = true;
          } else {
            this.isValidType = false;
          }
        } else {
          this.isGotData = true;
        }
      },
      (error) => {}
    );
  }

  insertBrand(brand) {
    if (this.brandList.indexOf(brand) <= -1) {
      this.brandList.push(brand);
    }
  }

  insertModel(model) {
    if (this.modelList.indexOf(model) <= -1) {
      this.modelList.push(model);
    }
  }

  formSubmit(): void {
    if (this.mcForm.valid) {
      this.modelData.number = this.searchText;
      this.next.emit(this.modelData);
    }
  }
}

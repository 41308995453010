import { environment } from '../../environments/environment';

export const urlConstant = {

    Vehicle: {
      GetVehicleDetail: environment.APIUrl + 'Vehicle/GetVehicleDetail'
    },
  Address: {
    getAddressDetail: environment.APIUrl + 'Address/GetAddressDetail',
    getAddressDetailsById: environment.APIUrl + 'Address/GetAddressDetailById'
  },
  MC: {
    GetMcDetail: environment.APIUrl + 'Vehicle/GetVehicleDetail'
  },
  Camping: {
    GetCampingDetail: environment.APIUrl + 'Vehicle/GetVehicleDetail'
  },
  Inquiry: {
    saveInquiry: environment.APIUrl + 'Inquiry/InsertInquirey',
  },
  Testimonial:{
    GetList: environment.APIUrl + 'Testimonial/GetActiveList',
  }
};

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { IndboService } from './indbo.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { OfferService } from '../offer/offer.service';
const addresses = ['Moldaugade', 'Moldrupsvej', 'Moldevej 9, st. th, 7100 Vejle', ' Fashion road'];

@Component({
  selector: 'app-indbo',
  templateUrl: './indbo.component.html',
  styleUrls: ['./indbo.component.scss']
})
export class IndboComponent implements OnInit {
  address: string;
  model: any = {address:null};
  years: any = [];
  addressList: any = [];
  info: any;
  @ViewChild('indboForm', { read: NgForm, static: false })
indboForm: NgForm;
  @Output() next = new EventEmitter<any>();
  @Input() resetIndboFormSubject: Subject<any> = new Subject<any>();
  constructor(private router: Router, private indboService: IndboService,
    private offerService: OfferService) { }

  ngOnInit() {

    let min = 18;
    const max = 120;

    while (min <= max) {
      const year = min + ' år';
      this.years.push(year);
      min++;
    }

  }

  ngAfterViewInit() {
    this.indboForm.statusChanges.subscribe((result) => {
      if (result == "VALID") {
        this.offerService.setIndboValidationObs(true);
      } else {
        this.offerService.setIndboValidationObs(false);
      }
      //     console.log(result)
    });
  }

  resultFormatBandListValue(value: any) {
    return value.tekst;
  }
  checkInputLength() {
    if (this.address.length) {
      this.getAddressDetails();
    } else {
      return;
    }
  }

  getSelectedItem(event) {
    this.model.address = event.item.tekst;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 0 ? []
        : this.addressList.filter(v => v.tekst.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  getAddressDetails() {
    this.indboService.getAddressDetail(this.address)
      .subscribe((res: any) => {
        if (res.data.data) {
          this.addressList = res.data.data;
        }
      }, error => {

      });
  }

  formSubmit(): void {
    if (this.indboForm.valid) {
       this.next.emit(this.model);
    }
  }

}

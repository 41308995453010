import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { AddressComponent } from './address/address.component';
import { OfferComponent } from './offer/offer.component';


const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        component: HomeComponent,
      }
      ]
  },

  {
    path: 'offer',
    component: OfferComponent,
    data: {
      title: 'Offer'
    },
    children: [
      {
        path: "user",
        component: UserComponent
      },

      {
        path: "vehicle",
        component: VehicleComponent
      },
      {
        path: "address",
        component: AddressComponent
      }
    ]
  },
  {
  path: '',
  component: HomeComponent,  pathMatch: 'full'
  },
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AddressService } from '../address/address.service';

@Component({
  selector: 'app-dine-information',
  templateUrl: './dine-information.component.html',
  styleUrls: ['./dine-information.component.scss']
})
export class DineInformationComponent implements OnInit {
  address: string;
  boligtype: number;
  addressList: any = [];
  addressId: any;
  model: any = {};
  livingSpace: any = [
    { key: 110, value: 'Stuehus til landbrugsejendom' },
    { key: 120, value: 'Fritliggende enfamiliehus' },
    { key: 130, value: 'Rækkehus eller dobbelthuse' },
    { key: 140, value: 'Etageboligbebyggelse' },
    { key: 510, value: 'Sommerhus' }
  ];
  @ViewChild('dineFormationForm', { read: NgForm, static: false }) dineFormationForm: NgForm;
  @Output() dineFormationFormSubmit = new EventEmitter<any>();
  dinAlderList: any = [];
  constructor(private addressService: AddressService,) { 
    
  }
  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(100),
    distinctUntilChanged(),
    map(term => term.length < 0 ? []
      : this.addressList.filter(v => v.tekst.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 50))
  )
resultFormatBandListValue(value: any) {
  return value.tekst;
}
  ngOnInit() {

    const  maxAlder = 120;
    const minAlder = 18;

    for (let i = minAlder; i <= maxAlder; i++) {
        const obj = i + ' år';
        this.dinAlderList.push(obj);
    }
    const Indboforsikring = JSON.parse(localStorage.getItem('Indboforsikring '));
    if(Indboforsikring){
      this.addressList.push({tekst:Indboforsikring.address, adresse: {adresseringsvejnavn: Indboforsikring.address}});
      this.address = this.addressList[0];
    }
  }

  checkInputLength() {
    if (this.address.length) {
      this.getAddressDetails();
    } else {
      return;
    }
  }

  getAddressDetails() {
    this.addressService.getAddressDetail(this.address)
      .subscribe((res: any) => {
        this.addressList = [];
        if (res.data.data) {
          this.addressList = res.data.data;
        }
      }, error => {

      });
  }

  getSelectedItem(event) {
    this.model = event.item.adresse;
    this.model.search = event.item;
    this.addressId = '';
    if (this.model) {
      this.addressId = this.model.id;
      this.getAddressDetailsById(this.addressId);
    }
  }

  getAddressDetailsById(id) {
    this.addressService.getAddressDetailsById(id)
      .subscribe((res: any) => {
         if (res && res.data.data) {
          const responseDetail = res.data.data[0];
          this.boligtype = responseDetail.ENH_ANVEND_KODE;
       }
      }, error => {

      });
  }

  formSubmit(): void {
    if (this.dineFormationForm.valid) {
      this.dineFormationFormSubmit.emit(this.dineFormationForm.value);
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferService } from './offer.service';
import { VehicleComponent } from '../vehicle/vehicle.component';
import { Subject } from 'rxjs';
import { CommonService } from '../common/commonService';
import * as _ from 'underscore';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  resetVehicleFormSubject: Subject<any> = new Subject<any>();
  resetAddressFormSubject: Subject<any> = new Subject<any>();
  resetAccidentHealthFormSubject: Subject<any> = new Subject<any>();
  resetMcFormSubject: Subject<any> = new Subject<any>();
  resetBoatFormSubject: Subject<any> = new Subject<any>();
  resetTravelFormSubject: Subject<any> = new Subject<any>();
  resetCampingFormSubject: Subject<any> = new Subject<any>();
  resetLifeFormSubject: Subject<any> = new Subject<any>();
  resetHoldingHomeFormSubject: Subject<any> = new Subject<any>();
  resetChangeOwnershipFormSubject: Subject<any> = new Subject<any>();
  resetAnimalFormSubject: Subject<any> = new Subject<any>();
  resetIndboFormSubject: Subject<any> = new Subject<any>();
  constructor(private route: ActivatedRoute, private router: Router
    , private offerService: OfferService, private commonService: CommonService
  ) { }

  selectedServices = [];
  notSelectedServices = [];
  insuranceData = {};

  currentActiveElement = { isCompleted: false, name: '' };
  currentActiveIndex: number = -1;
  isPreCompleted = false;
  userModel: any = {
    Cars: [],
    Homes: [],
    Accident: [],
    OwnerShip: [],
    Rejse: [],
    Indbo: [],
    Dry: [],
    Boat: [],
    Camping: [],
    MotorCycle: [],
    Life: []
  };

  defaultKeyValue: any;
  ngOnInit() {
    // localStorage.clear();
    const queryParamMap = this.route.snapshot.queryParamMap;
    this.insuranceData = {
      "bill": {
        "count": parseInt(queryParamMap.get('bill')) || 0,
        "max": 3,
        "data": { name: 'Bilforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Bilblack.svg', iconWhite: '../../assets/icons/innerpage/Bilwhite.svg' }
      },
      "home": {
        "count": parseInt(queryParamMap.get('Hus')) || 0,
        "max": 5,
        "data": { name: 'Husforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Husblack.svg', iconWhite: '../../assets/icons/innerpage/Huswhite.svg' }
      },
      "accident ": {
        "count": parseInt(queryParamMap.get('Ulykke')) || 0,
        "max": 1,
        "data": { name: 'Ulykkesforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Ulykkeblack.svg', iconWhite: '../../assets/icons/innerpage/Ulykkewhite.svg' }
      },
      "mc": {
        "count": parseInt(queryParamMap.get('MC')) || 0,
        "max": 2,
        "data": { name: 'MC, knallert m.fl.', isCompleted: false, iconBlack: '../../assets/icons/innerpage/MCATVblack.svg', iconWhite: '../../assets/icons/innerpage/MCATVwhite.svg' }
      },
      "boat": {
        "count": parseInt(queryParamMap.get('baad')) || 0,
        "max": 2,
        "data": { name: 'Bådforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Bådblack.svg', iconWhite: '../../assets/icons/innerpage/Bådwhite.svg' }
      },
      "travel": {
        "count": parseInt(queryParamMap.get('Rejse')) || 0,
        "max": 1,
        "data": { name: 'Rejseforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Rejseblack.svg', iconWhite: '../../assets/icons/innerpage/Rejsewhite.svg' }
      },
      "camping": {
        "count": parseInt(queryParamMap.get('camping')) || 0,
        "max": 2,
        "data": { name: 'Campingforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Campingblack.svg', iconWhite: '../../assets/icons/innerpage/Campingwhite.svg' }
      },
      "life": {
        "count": parseInt(queryParamMap.get('liv')) || 0,
        "max": 1,
        "data": { name: 'Livsforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Livblack.svg', iconWhite: '../../assets/icons/innerpage/Livwhite.svg' }
      },
      "holdingHome": {
        "count": parseInt(queryParamMap.get('Fritidshus')) || 0,
        "max": 1,
        "data": { name: 'Fritidshusforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Fritidshusblack.svg', iconWhite: '../../assets/icons/innerpage/Fritidshuswhite.svg' }
      },
      "changeOwnership": {
        "count": parseInt(queryParamMap.get('Ejerskifte')) || 0,
        "max": 1,
        "data": { name: 'Ejerskifteforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Ejerskifteblack.svg', iconWhite: '../../assets/icons/innerpage/Ejerskiftewhite.svg' }
      },
      "animals": {
        "count": parseInt(queryParamMap.get('dyr')) || 0,
        "max": 1,
        "data": { name: 'Dyreforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Dyrblack.svg', iconWhite: '../../assets/icons/innerpage/Dyrwhite.svg' }
      },
      "indbo": {
        "count": parseInt(queryParamMap.get('Indbo')) || 0,
        "max": 1,
        "data": { name: 'Indboforsikring', isCompleted: false, iconBlack: '../../assets/icons/innerpage/Indboblack.svg', iconWhite: '../../assets/icons/innerpage/Indbowhite.svg' }
      }
    };
    this.notSelectedService();
    window.scrollTo(0, 0);

    if (this.selectedServices.length > 0) {
      this.displayCarScreen(this.selectedServices[0], 0);
    }
  }

  menuopen() {
    if (document.body.classList && document.body.classList.length == 0) {
      document.body.classList.add('offer-menu-active');
    } else {
      document.body.classList.remove('offer-menu-active');
    }
  }

  notSelectedService(): void {
    for (const key in this.insuranceData) {
      if (Object.prototype.hasOwnProperty.call(this.insuranceData, key)) {
        const notSelected = this.insuranceData[key];

        if (notSelected['max'] === 1 && notSelected['count'] === 0) {
          this.notSelectedServices.push(notSelected['data']);
        } else if (notSelected['count'] <= notSelected['max']) {

          for (let i = 0; i < notSelected['count']; i++) {
            const nSelected = Object.assign({}, notSelected['data']);
            nSelected['name'] = `${nSelected['name']} ${i > 0 ? (i + 1) : ''}`;
            this.selectedServices.push(nSelected);
          }

          for (let i = notSelected['count'] || 0; i < notSelected['max']; i++) {
            const nSelected = Object.assign({}, notSelected['data']);
            nSelected['name'] = `${nSelected['name']} ${i > 0 ? (i + 1) : ''}`;
            this.notSelectedServices.push(nSelected);
          }
        }
      }
    }
  }

  addToSelected(item, index) {
    this.selectedServices.push(item);
    this.notSelectedServices.splice(index, 1);
  }

  removeFromSelected(item, index) {
    this.notSelectedServices.push(item);
    this.selectedServices.splice(index, 1);
  }

  displayCarScreen(item, i = -1) {
    this.currentActiveElement = { isCompleted: false, name: '' };

    setTimeout(() => {
      this.currentActiveElement = item || { isCompleted: false, name: 'DineInformation' };
      this.currentActiveIndex = i;
      this.defaultKeyValue = item;
      //this.resetVehicleFormSubject.next(item);
      window.scrollTo(0, 0);
    }, 0);
  }

  nextStep(): void {
    if (this.currentActiveIndex < this.selectedServices.length) {
      this.currentActiveIndex++;
      this.displayCarScreen(this.selectedServices[this.currentActiveIndex], this.currentActiveIndex);
    }
  }

  previousStep(): void {
    if (this.currentActiveIndex > 0) {
      this.currentActiveIndex--;
      this.displayCarScreen(this.selectedServices[this.currentActiveIndex], this.currentActiveIndex);
    }
  }

  vehicleNext(event: any): void {
    // console.log('vehicleNext : ', event);
    const obj = {
      number: event.number,
      brand: event.brand,
      model: event.model,
      engineSize: event.engineSize,
      year: event.year,
      yearWithOwnCar: event.yearWithOwnCar,
      coverage: (event.selectedItems && event.selectedItems.length > 0 ? _.pluck(event.selectedItems, 'item_text').join(',') : ''),
      otherInformation: event.otherInformation
    };
    this.userModel.Cars.push(obj);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(obj));
    this.nextStep();
  }

  ulykkeNext(event: any): void {
    // console.log('ulykkeNext : ', event);
    const obj = {
      adults: event.adults,
      childern: event.childern,
      adultOne: event.adultOne,
      adultTwo: event.adultTwo,
      childernOne: event.childernOne,
      childernTwo: event.childernTwo,
      childernThree: event.childernThree,
      childernFour: event.childernFour,
      childernFive: event.childernFive,
      otherInformation: event.relInfo
    };
    this.userModel.Accident.push(obj);
    console.log('ulykkeNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(obj));
    this.nextStep();
  }

  husNext(event: any): void {
    const obj = {
      address: event.search.tekst,
      tagType: event.tag,
      yearOfConstuction: event.year,
      livingSpace: event.place,
      housingType: event.housingType,
      counselorVisit: true,
      otherInformation: event.oply,
      isSummerHus: event.isSummerHus
    };
    this.userModel.Homes.push(obj);
    console.log('husNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(obj));
    this.nextStep();
  }

  ownerShipNext(event: any): void {
    const obj = {
      address: event.search.tekst,
      hvilken: event.hvilken,
      yearOfConstuction: event.year,
      livingSpace: event.place,
      housingType: event.housingType,
      boligens: event.boligens,
      uploadtilstandrapport: event.uploadtilstandrapport,
      uploadelrapport: event.uploadelrapport,
      otherInformation: event.oply
    };
    this.userModel.OwnerShip.push(obj);
    console.log('ownerShipNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(obj));
    this.nextStep();
  }

  rejseNext(event: any): void {
    this.userModel.Rejse.push(event);
    console.log('rejseNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(event));
    this.nextStep();
  }

  indboNext(event: any): void {
    this.userModel.Indbo.push(event);
    console.log('indboNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(event));
    this.nextStep();
  }

  dryNext(event: any): void {
    this.userModel.Dry.push(event);
    console.log('dryNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(event));
    this.nextStep();
  }

  boatNext = (event: any) => {
    event.tilvalg = (event.selectedItems && event.selectedItems.length > 0 ? _.pluck(event.selectedItems, 'item_text').join(',') : ''),
      this.userModel.Boat.push(event);
    console.log('boatNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(event));
    this.nextStep();
  }

  campingNext = (event: any) => {
    const obj = {
      number: event.number,
      brand: event.brand,
      model: event.model,
      cavardi: event.Cavardi,
      isInsurance: event.isInsurance,
      otherInformation: event.relInfo
    };
    this.userModel.Camping.push(obj);
    console.log('campingNext : ', event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(event));
    this.nextStep();
  }

  motorCycleNext(event: any): void {
    const obj = {
      number: event.number,
      brandmodel: event.brandmodel,
      age: event.age,
      vehicle: event.vehicle,
      year: event.year,
      run: event.run,
      vehicleparked: (event.selectedItems && event.selectedItems.length > 0 ? _.pluck(event.selectedItems, 'item_text').join(',') : ''),
      isconselorvisit:  event.isconselorvisit,
      otherInformation: event.relInfo
    };
    this.userModel.MotorCycle.push(obj);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(obj));
    this.nextStep();
  }

  
  lifeNext(event: any): void {
    this.userModel.Life.push(event);
    localStorage.setItem(this.currentActiveElement.name, JSON.stringify(event));
    this.nextStep();
  }

  dineFormationFormSubmit(event: any) {
    this.userModel.phoneNumber = event.ditTelefonnummer;
    this.userModel.name = event.ditNavn;
    this.userModel.address = event.search ? event.search.tekst : "";
    this.userModel.housingType = event.boligtype;
    this.userModel.numberofPersons = event.antalPersonerHusstanden;
    this.userModel.email = event.dinEmailAdresse;
    this.userModel.age = event.dinAlder;
    this.userModel.inRKI = event.erDuRKI;
    this.userModel.Injuries = event.skaderDeSenesteAr;
    this.userModel.currentInsuranceCompany = event.nuværendeForsikringsselskab;
    this.userModel.organizations = event.erDuMedlemAfCOOP;
    this.userModel.bank = event.hvilketPengeinstitutHarDu;
    this.userModel.memberOfCoop = event.erDuMedlemAfCOOP;
    this.userModel.haveOtherCompany = event.hvilketPengeinstitutHarDu;
    this.userModel.acceptConsent = event.erDuRKI;
    this.userModel.acceptCondition = event.erDuRKI;
    this.userModel.getOffers = event.erDuRKI;
    this.userModel.OtherInformation = event.erDuRKI;
    console.log('dineFormationFormSubmit : ', event);

    this.offerService.saveInquiry(this.userModel)
      .subscribe((res: any) => {
        if (res && res.messageType === 'success') {
          this.commonService.toastSuccessMsg('Success', 'Tak! Vi sørger for at du bliver kontaktet af 3 selskaber med deres skarpeste tilbud. God forsikringsjagt!');
          this.router.navigate(['/']);
        } else {
          this.commonService.toastErrorMsg('Error', 'Your inquiry has been sent failed.');
        }
      }, error => {

      });
  }
}

import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { CampingService } from './camping.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OfferService } from '../offer/offer.service';
import { Subject } from 'rxjs';
import { dataConstant } from '../constant/dataConstant';

@Component({
  selector: 'app-camping',
  templateUrl: './camping.component.html',
  styleUrls: ['./camping.component.scss']
})
export class CampingComponent implements OnInit {

  searchText: string;
  isGotData = false;
  brandList = dataConstant.CampBrandList;
  modelList = dataConstant.CampModelList;
  engineSizeList = ['0,5 liter', '0,6 liter', '0,7 liter', '0,8 liter', '0,9 liter', '1,0 liter', '1,1 liter', '1149'];
  yearList = ['2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012'];
  noOfYearsList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'More than 10', 'Dont know'];
  validType = ['Campingvogn'];
  isValidType = true;
  brand = '';
  type = '';
  model = '';
  engineSize = '';
  year = '';
  relInfo = '';
  Cavardi = '';
  noOfYear = '';
  dropdownList = [];
  selectedItems = [];
  modelData = { id: 1, number:'', brand: this.brand, model: this.model, engineSize: this.engineSize, Cavardi: '', type: '', year: this.year, relInfo: this.relInfo, noOfYear: this.noOfYear, eleName: '', isCompleted: false };
  dropdownSettings: IDropdownSettings;
  @ViewChild('campingForm', { read: NgForm, static: false })
  campingForm: NgForm;
  @Input() resetCampingFormSubject: Subject<any> = new Subject<any>();
  @Output() next = new EventEmitter<any>();

  constructor(private router: Router, private campingService: CampingService,
    private offerService: OfferService, private cd: ChangeDetectorRef) { }

  ngOnInit() {

    this.dropdownList = [
      { item_id: 1, item_text: 'Ansvarsforsikring' },
      { item_id: 2, item_text: 'Kaskoforsikring' }
    ];
    this.selectedItems = [
      { item_id: 1, item_text: 'Ansvarsforsikring' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Vælg alt',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.resetCampingFormSubject.subscribe(response => {
      if (response) {
        var element = localStorage.getItem(response.name);
        if (element) {
          var data = JSON.parse(element);
          this.modelData = data;
          this.isGotData = true;
          this.cd.detectChanges();
        }
        else if (this.modelData.eleName !== response.name && response.isCompleted) {
          this.modelData.eleName = response.name;
          this.modelData.isCompleted = response.isCompleted;
          localStorage.setItem(response.name, JSON.stringify(this.modelData));
        }
        else {
          this.campingForm.reset();
          this.isGotData = false;
          this.searchText = '';
        }
      }
    });

  }

  ngAfterViewInit() {
    // this.campingForm.statusChanges.subscribe((result) => {
    //   if (result === 'VALID') {
    //     this.offerService.setMCValidationObs(true);
    //   }
    //   //     console.log(result)
    // });
  }


  checkInputLength() {
    this.isValidType = true;
    if (this.searchText.length > 4) {
      this.getCampingDetail();
    } else {
      return;
    }
  }

  getCampingDetail() {
    this.campingService.getCampingDetails(this.searchText)
      .subscribe((res: any) => {
        if (res.data.data) {
          if (res.data.data.type && this.validType.indexOf(res.data.data.type) > -1) {
          this.insertBrand(res.data.data.make);
          this.insertModel(res.data.data.model);
          this.modelData.brand = res.data.data.make;
          this.modelData.model = res.data.data.model;
          this.modelData.engineSize = res.data.data.engine_volume;
          this.modelData.year = res.data.data.model_year;
          this.modelData.type = res.data.data.type;
          this.isGotData = true;
        }
        else {
          this.isValidType = false;
        }
        } else {
          this.isGotData = true;
        }
      }, error => {

      });
  }

  insertBrand(brand) {
    if (this.brandList.indexOf(brand) <= -1) {
      this.brandList.push(brand);
    }
  }

  insertModel(model) {
    if (this.modelList.indexOf(model) <= -1) {
      this.modelList.push(model);
    }
  }

  formSubmit(): void {
    if (this.campingForm.valid) {
      this.modelData.number = this.searchText;
      this.next.emit(this.modelData);
    }
  }

}

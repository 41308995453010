import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common/common.service';
import { urlConstant } from '../constants/UrlConstant';

@Injectable({
  providedIn: 'root'
})
export class MCAtvMopedService {

  constructor(public commonService: CommonService, public http: HttpClient) { }

  getMcDetails(plateNum) {
    const model = { vehicleNumber: plateNum };
    return this.commonService.post(urlConstant.MC.GetMcDetail, JSON.stringify(model))
      .pipe(map((x: Response) => x));
  }
}

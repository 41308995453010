import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NotificationService} from './common/notification.service';
import { VehicleComponent } from './vehicle/vehicle.component';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddressComponent } from './address/address.component';
import { NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { OfferComponent } from './offer/offer.component';
import { IndboComponent } from './indbo/indbo.component';
import { AccidenHealthComponent } from './acciden-health/acciden-health.component';
import { TripComponent } from './trip/trip.component';
import { MCAtvMopedComponent } from './mcatv-moped/mcatv-moped.component';
import { HoldingHomeComponent } from './holding-home/holding-home.component';
import { ChangeOfOwnershipComponent } from './change-of-ownership/change-of-ownership.component';
import { BoatComponent } from './boat/boat.component';
import { CampingComponent } from './camping/camping.component';
import { LifeComponent } from './life/life.component';
import { AnimalComponent } from './animal/animal.component';
import { DemoComponent } from './demo/demo.component';
import { DineInformationComponent } from './dine-information/dine-information.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];
@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    UserComponent,
    VehicleComponent,
    AddressComponent,
    OfferComponent,
    IndboComponent,
    AccidenHealthComponent,
    TripComponent,
    MCAtvMopedComponent,
    HoldingHomeComponent,
    ChangeOfOwnershipComponent,
    BoatComponent,
    CampingComponent,
    LifeComponent,
    AnimalComponent,
    DemoComponent,
    DineInformationComponent
  ],
  imports: [
    FormsModule ,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbPopoverModule,
    NgbTypeaheadModule,
    AutocompleteLibModule
  ],
  providers: [NotificationService],
  bootstrap: [AppComponent]
})
export class AppModule { }

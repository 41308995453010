import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-boat',
  templateUrl: './boat.component.html',
  styleUrls: ['./boat.component.scss']
})
export class BoatComponent implements OnInit {
  @ViewChild('boatForm', { read: NgForm, static: false })
  boatForm: NgForm;
  @Input() resetBoatFormSubject: Subject<any> = new Subject<any>();
  @Output() next = new EventEmitter<any>();
  
  modelData: any = {};
  dropdownList: any = [];
  antalFodList: any = [];
  DakningList: any = ['Dansk farvand', 'Skandinavisk farvand','Europæisk farvand','Internationalt farvand','Ved ikke' ]
  dropdownSettings: IDropdownSettings;
  motorTypeList: any = ['Indenbords','Påhæng','Andet','Ved ikke'];
  byggearList: any = [];
  liggepladsList: any = ['I havn','På bådtrailer','På land','Andet'];
  constructor() { }

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Ansvarsforsikring' },
      { item_id: 2, item_text: 'Kaskoforsikring' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Vælg alt',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.setAntalFod();
    this.setByggearList();
  }

  setAntalFod() {
    for(let i = 5; i<50;i++) {
      this.antalFodList.push(i + ' fod');
    }
    this.antalFodList.push('Mere end 50 fod');
  }

  setByggearList() {
    for(let i = 2021; i>=1900;i--) {
      this.byggearList.push(i.toString());
    }
  }

  formSubmit(): void {
    if (this.boatForm.valid) {
      this.next.emit(this.modelData);
    }
  }

}

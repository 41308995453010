import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AccidenHealthService } from './acciden-health.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OfferService } from '../offer/offer.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-acciden-health',
  templateUrl: './acciden-health.component.html',
  styleUrls: ['./acciden-health.component.scss']
})
export class AccidenHealthComponent implements OnInit {
  @ViewChild('accedentalForm', { read: NgForm, static: false })
  accedentalForm: NgForm;
  dropdownSettings: IDropdownSettings;
  modelData: any;
  isGotData = false;
  searchText: string;
  @Input() resetAccidentHealthFormSubject: Subject<any> = new Subject<any>();
  @Output() next = new EventEmitter<any>();
  
  constructor(private route: ActivatedRoute,private router: Router, private accidenHealthService: AccidenHealthService,
    private offerService: OfferService,
    private cd: ChangeDetectorRef) { }

  adultNumberList: any = [
    { key: 0, value: "0" },
    { key: 1, value: "1" },
    { key: 2, value: "2" },
    { key: 2, value: "Flere end 2" },
  ];
  childNumberList: any = [
    { key: 0, value: "0" },
    { key: 1, value: "1" },
    { key: 2, value: "2" },
    { key: 3, value: "3" },
    { key: 4, value: "4" },
    { key: 5, value: "5" },
    { key: 5, value: "Flere end 5" },
  ];
  model = {
    adultNumber: null,
    childNumber: null,
    info:null
  };

  adults = [];
  childs = [];

  adultAge = [];
  childAge = [];

  ngOnInit() {
    this.resetAccidentHealthFormSubject.subscribe(response => {
      if (response) {
        const element = localStorage.getItem(response.name);
        if (element) {
          const data = JSON.parse(element);
          this.modelData = data;
          this.isGotData = true;
          this.cd.detectChanges();
        } else if (this.modelData.eleName !== response.name && response.isCompleted) {
          this.modelData.eleName = response.name;
          this.modelData.isCompleted = response.isCompleted;
          localStorage.setItem(response.name, JSON.stringify(this.modelData));
        } else {
          this.accedentalForm.reset();
          this.isGotData = false;
          this.searchText = '';
        }
      }
    });


    //var Param = this.route.snapshot.queryParamMap.get('Ulykke');
    //this.updateAdults(Param);
    //this.model.adultNumber = Param;
    for (var i = 18; i <= 120; i++) {
      this.adultAge.push({ key: i, value: i + " år" })
    }
    for (var i = 0; i < 18; i++) {
      this.childAge.push({ key: i, value: i + " år" })
    }
  }


  updateAdults(num) {
    this.adults = [];
    for (var i = 0; i < num; i++) {
      var AdultData = {
        age: null
      }
      this.adults.push(AdultData);
    }
  }


  updateChilds(num) {
    this.childs = [];
    for (var i = 0; i < num; i++) {
      var ChildData = {
        age: null
      }
      this.childs.push(ChildData);
    }
  }

  formSubmit(form): void {
    const obj = {
      adults: this.model.adultNumber,
      childern:this.model.childNumber,
      relInfo: this.model.info,
      adultOne: null,
      adultTwo: null,
      childernOne: null,
      childernTwo:null,
      childernThree: null,
      childernFour: null,
      childernFive: null,
    };
    for (const [key, value] of Object.entries(this.adults)) {
      console.log(key, value);
      if(key == "0"){
        obj.adultOne = value.age;
      }
      if(key == "1"){
        obj.adultTwo = value.age;
      }
       }
    for (const [key, value] of Object.entries(this.childs)) {
      if(key == "0"){
        obj.childernOne = value.age;
      }
      if(key == "1"){
        obj.childernTwo = value.age;
      }
      if(key == "2"){
        obj.childernThree = value.age;
      }
      if(key == "3"){
        obj.childernFour = value.age;
      }
      if(key == "4"){
        obj.childernFive = value.age;
      }
    }
    if (form && form.valid) {
      this.next.emit(obj);
    }
  }

}

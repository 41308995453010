import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AnimalService } from './animal.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OfferService } from '../offer/offer.service';
import { Subject } from 'rxjs';
import { dataConstant } from '../constant/dataConstant';

@Component({
  selector: 'app-animal',
  templateUrl: './animal.component.html',
  styleUrls: ['./animal.component.scss']
})
export class AnimalComponent implements OnInit {
  @ViewChild('animalForm', { read: NgForm, static: false })
  animalForm: NgForm;
  @Output() next = new EventEmitter<any>();
  dropdownSettings: IDropdownSettings;
  hestRaceList = dataConstant.HestRace;
  hundRaceList = dataConstant.HundRace;
  katRaceList = dataConstant.KatRace;
  smadyrRaceList = dataConstant.SmadyrRace;
  dryFirstRaceList = [];
  drySecondRaceList = [];
  dryThirdRaceList = [];
  model: any = {
    animalSpecies1: undefined,
    isCompleted: false
  };
  yearsList = [];
  @Input() resetAnimalFormSubject: Subject<any> = new Subject<any>();

  constructor(private router: Router, private animalService: AnimalService,
    private offerService: OfferService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {


    this.resetAnimalFormSubject.subscribe(response => {
      if (response) {
        const element = localStorage.getItem(response.name);
        if (element) {
          const data = JSON.parse(element);
          this.model = data;
          this.cd.detectChanges();
        } else if (this.model.eleName !== response.name && response.isCompleted) {
          this.model.eleName = response.name;
          this.model.isCompleted = response.isCompleted;
          localStorage.setItem(response.name, JSON.stringify(this.model));
        } else {
          this.animalForm.reset();
        }
      }
    });
    const dt = new Date();
    let year = dt.getFullYear();
    const endYear = (year - 50);
    while (year >= endYear) {
      this.yearsList.push(year--);
    }
  }

  ngAfterViewInit() {
    this.animalForm.statusChanges.subscribe((result) => {
      if (result === 'VALID') {
        this.offerService.setAnimalValidationObs(true);
      }
      //     console.log(result)
    });
  }

  IsValidDetail(type) {
    if(type == 1){
      if(this.model.animalSpecies1 == "Hest" ){
        this.dryFirstRaceList = this.hestRaceList;
      }
      else if(this.model.animalSpecies1 == "Hund" ){
        this.dryFirstRaceList = this.hundRaceList;
      }
      else if(this.model.animalSpecies1 == "Kat" ){
        this.dryFirstRaceList = this.katRaceList;
      }
      else if(this.model.animalSpecies1 == "Smadyr" ){
        this.dryFirstRaceList = this.smadyrRaceList;
      }
    }
    else if(type == 2){
      if(this.model.animalSpecies2 == "Hest" ){
        this.drySecondRaceList = this.hestRaceList;
      }
      else if(this.model.animalSpecies2 == "Hund" ){
        this.drySecondRaceList = this.hundRaceList;
      }
      else if(this.model.animalSpecies2 == "Kat" ){
        this.drySecondRaceList = this.katRaceList;
      }
      else if(this.model.animalSpecies2 == "Smadyr" ){
        this.drySecondRaceList = this.smadyrRaceList;
      }
    }
    else if(type == 3){
      if(this.model.animalSpecies3 == "Hest" ){
        this.dryThirdRaceList = this.hestRaceList;
      }
      else if(this.model.animalSpecies3 == "Hund" ){
        this.dryThirdRaceList = this.hundRaceList;
      }
      else if(this.model.animalSpecies3 == "Kat" ){
        this.dryThirdRaceList = this.katRaceList;
      }
      else if(this.model.animalSpecies3 == "Smadyr" ){
        this.dryThirdRaceList = this.smadyrRaceList;
      }
    }
    // if (this.animalForm.form.status === 'VALID') {
    //   this.offerService.setAnimalValidationObs(true);
    // } else {
    //   this.offerService.setAnimalValidationObs(false);
    // }

  }

  formSubmit(): void {
    if (this.animalForm.valid) {
      this.next.emit(this.model);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NotificationService } from '../common/notification.service';
import { OfferService } from '../offer/offer.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  testimonialList : any = [];
  insurances: any = {
    bil: 0,
    Indbo: 0,
    Ulykke: 0,
    Hus: 0,
    Rejse: 0,
    MC: 0,
    Fritidshus: 0,
    Ejerskifte: 0,
    Baad: 0,
    Camping: 0,
    Liv: 0,
    Dyr: 0
  };

  isValidService = false;

  constructor(private router: Router,
     private route: ActivatedRoute, 
     private notifyService: NotificationService,
     private offerService: OfferService, 
     private commonService: CommonService) {

  }

  ngOnInit() {
    const vehical = localStorage.getItem('Bilforsikring ');
    const house = localStorage.getItem('Husforsikring ');
    this.getTestimonial();
    if (vehical != null || house != null) {

      localStorage.removeItem('Bilforsikring ');
      localStorage.removeItem('Husforsikring ');
      location.reload();
    }
  }

  getTestimonial() {
    this.offerService.getTestimonial()
      .subscribe((res: any) => {
        if (res.data) {
          this.testimonialList = res.data;
        }
      }, error => {
        this.commonService.toastErrorMsg("Error", error);
      });
  }

  // for decreased a service for selected
  decrementService(serviceName) {
    if (serviceName === 'bil') {
      this.insurances.bil -= 1;
    } else if (serviceName === 'Indbo') {
      this.insurances.Indbo -= 1;
    } else if (serviceName === 'Ulykke') {
      this.insurances.Ulykke -= 1;
    } else if (serviceName === 'Hus') {
      this.insurances.Hus -= 1;
    } else if (serviceName === 'Rejse') {
      this.insurances.Rejse -= 1;
    } else if (serviceName === 'MC') {
      this.insurances.MC -= 1;
    } else if (serviceName === 'Fritidshus') {
      this.insurances.Fritidshus -= 1;
    } else if (serviceName === 'Ejerskifte') {
      this.insurances.Ejerskifte -= 1;
    } else if (serviceName === 'Baad') {
      this.insurances.Baad -= 1;
    } else if (serviceName === 'Camping') {
      this.insurances.Camping -= 1;
    } else if (serviceName === 'Liv') {
      this.insurances.Liv -= 1;
    } else if (serviceName === 'Dyr') {
      this.insurances.Dyr -= 1;
    }
  }

  // for Increased a service for selected
  incrementService(serviceName, isBox) {
    if (isBox) {
      if (serviceName === 'bil' && this.insurances.bil == 0) {
        this.insurances.bil += 1;
      } else if (serviceName === 'Indbo' && this.insurances.Indbo == 0) {
        this.insurances.Indbo += 1;
      } else if (serviceName === 'Ulykke' && this.insurances.Ulykke == 0) {
        this.insurances.Ulykke += 1;
      } else if (serviceName === 'Hus' && this.insurances.Hus == 0) {
        this.insurances.Hus += 1;
      } else if (serviceName === 'Rejse' && this.insurances.Rejse == 0) {
        this.insurances.Rejse += 1;
      } else if (serviceName === 'MC' && this.insurances.MC == 0) {
        this.insurances.MC += 1;
      } else if (serviceName === 'Fritidshus' && this.insurances.Fritidshus == 0) {
        this.insurances.Fritidshus += 1;
      } else if (serviceName === 'Ejerskifte' && this.insurances.Ejerskifte == 0) {
        this.insurances.Ejerskifte += 1;
      } else if (serviceName === 'Baad' && this.insurances.Baad == 0) {
        this.insurances.Baad += 1;
      } else if (serviceName === 'Camping' && this.insurances.Camping == 0) {
        this.insurances.Camping += 1;
      } else if (serviceName === 'Liv' && this.insurances.Liv == 0) {
        this.insurances.Liv += 1;
      } else if (serviceName === 'Dyr' && this.insurances.Dyr == 0) {
        this.insurances.Dyr += 1;
      }
    }
    else {


      if (serviceName === 'bil') {
        this.insurances.bil += 1;
      } else if (serviceName === 'Indbo') {
        this.insurances.Indbo += 1;
      } else if (serviceName === 'Ulykke') {
        this.insurances.Ulykke += 1;
      } else if (serviceName === 'Hus') {
        this.insurances.Hus += 1;
      } else if (serviceName === 'Rejse') {
        this.insurances.Rejse += 1;
      } else if (serviceName === 'MC') {
        this.insurances.MC += 1;
      } else if (serviceName === 'Fritidshus') {
        this.insurances.Fritidshus += 1;
      } else if (serviceName === 'Ejerskifte') {
        this.insurances.Ejerskifte += 1;
      } else if (serviceName === 'Baad') {
        this.insurances.Baad += 1;
      } else if (serviceName === 'Camping') {
        this.insurances.Camping += 1;
      } else if (serviceName === 'Liv') {
        this.insurances.Liv += 1;
      } else if (serviceName === 'Dyr') {
        this.insurances.Dyr += 1;
      }
    }
  }

  // check for get obtain offer detail
  validateService() {
    if (this.insurances.bil > 0 || this.insurances.Indbo > 0 || this.insurances.Ulykke > 0 ||
      this.insurances.Hus > 0 || this.insurances.Rejse > 0 || this.insurances.MC > 0
      || this.insurances.Fritidshus > 0 || this.insurances.Ejerskifte > 0 || this.insurances.Baad > 0
      || this.insurances.Camping > 0 || this.insurances.Liv > 0 || this.insurances.Dyr > 0) {
      this.isValidService = true;
    } else {
      this.isValidService = false;
      this.notifyService.showError('Please vælg at least one service.', 'Error');
    }

    return this.isValidService;
  }


  goToVehicle() {
    if (this.validateService()) {
      let queryParam = '?';
      if (this.insurances.bil > 0) {
        queryParam += 'bill=' + this.insurances.bil + '&';
      }
      if (this.insurances.Indbo > 0) {
        queryParam += 'Indbo=' + this.insurances.Indbo + '&';
      }
      if (this.insurances.Ulykke > 0) {
        queryParam += 'Ulykke=' + this.insurances.Ulykke + '&';
      }
      if (this.insurances.Hus > 0) {
        queryParam += 'Hus=' + this.insurances.Hus + '&';
      }
      if (this.insurances.Rejse > 0) {
        queryParam += 'Rejse=' + this.insurances.Rejse + '&';
      }
      if (this.insurances.MC > 0) {
        queryParam += 'MC=' + this.insurances.MC + '&';
      }
      if (this.insurances.Fritidshus > 0) {
        queryParam += 'Fritidshus=' + this.insurances.Fritidshus + '&';
      }
      if (this.insurances.Ejerskifte > 0) {
        queryParam += 'Ejerskifte=' + this.insurances.Ejerskifte + '&';
      }
      if (this.insurances.Bat > 0) {
        queryParam += 'bat=' + this.insurances.Bat + '&';
      }
      if (this.insurances.Camping > 0) {
        queryParam += 'camping=' + this.insurances.Camping + '&';
      }
      if (this.insurances.Liv > 0) {
        queryParam += 'liv=' + this.insurances.Liv + '&';
      }
      if (this.insurances.Dyr > 0) {
        queryParam += 'dyr=' + this.insurances.Dyr + '&';
      }
      if (this.insurances.Baad > 0) {
        queryParam += 'baad=' + this.insurances.Baad + '&';
      }
      this.router.navigateByUrl('/offer' + queryParam);
    }
  }
}

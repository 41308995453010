import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { LifeService } from './life.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OfferService } from '../offer/offer.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-life',
  templateUrl: './life.component.html',
  styleUrls: ['./life.component.scss']
})
export class LifeComponent implements OnInit {
  dropdownSettings: IDropdownSettings;
  model: any = {
    isCompleted: false
  };
  yearsList = [];
  businessList = ['Bygge og anlæg', 'Handel, transport og service',
  'Havne, jernbaner og elektricitet', 'Industri og håndværk', 'IT og kommunikation',
   'Ingeniør og teknik', 'Kontor og økonomi'];
   sumInsurance = ['0 kr','100.000 kr','200.000 kr','300.000 kr','400.000 kr',
   '500.000 kr','600.000 kr','700.000 kr'];

   @ViewChild('lifeForm', { read: NgForm, static: false }) lifeForm: NgForm;
   @Output() next = new EventEmitter<any>();

   @Input() resetLifeFormSubject: Subject<any> = new Subject<any>();
  constructor(private offerService: OfferService) { }

  ngOnInit() {
    // this.resetLifeFormSubject.subscribe(response => {
    //   if (response) {
    //     const element = localStorage.getItem(response.name);
    //     if (element) {
    //       const data = JSON.parse(element);
    //       this.model = data;

    //       this.cd.detectChanges();
    //     } else if (this.model.eleName !== response.name && response.isCompleted) {
    //       this.model.eleName = response.name;
    //       this.model.isCompleted = response.isCompleted;
    //       localStorage.setItem(response.name, JSON.stringify(this.model));
    //     } else {
    //       this.lifeForm.reset();
    //     }
    //   }
    // });

    let Startyear = 18;
    const endYear = 70;
    while (Startyear <= endYear) {
      const year = Startyear + ' year';
      this.yearsList.push(year);
      Startyear++;
    }

  }

  ngAfterViewInit() {
    this.lifeForm.statusChanges.subscribe((result) => {
      if (result === "VALID") {
        this.offerService.setLifeValidationObs(true);
      }
      //     console.log(result)
    });
  }

  formSubmit(): void {
    if (this.lifeForm.valid) {
      this.next.emit(this.model);
    }
  }

}
